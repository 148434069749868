export const CHANGE_PHRASE = "CHANGE_PHRASE";
export const DISABLE_CHARACTER_MOVING = "DISABLE_CHARACTER_MOVING";

const initState = {
  currentAvatarPhrase: 0,
  avatarPhrasesEn: [
    'Whats up with this little creature over there?',
    'Hey, click on the creature, let´s find out whats going on.',
  ],
  avatarPhrasesDe: [
    'Was ist denn mit diesem kleinen Geschöpf los?',
    'Hey, klicke auf das Geschöpf und finde heraus, was los ist.',
  ],
  animalPhrasesEn: [
    'Oh no, oh no, oh no.',
    'If you click on me in the inventory and drag me to the right position, you can drop me there.',
    `Yeaahh! Thank you so much! I think you are just the perfect person to help us! 
    Do you see the Play Button on me in the inventory? As I am at the correct position now, 
    you can click on it and learn more. But who are you actually?`
  ],
  animalPhrasesDE: [
    'Oh nein, oh nein, oh nein.',
    'Du kannst mich aus dem Inventar in die Vitrine ziehen und dort positionieren.',
    `Yeaaah! Supergroßes XXL Dankeschön! Ich glaube Du bist genau die richtige Person, 
    um uns zu helfen. Siehst Du den Play Button, der im Inventory erschienen ist? Da ich mich jetzt an der 
    richtigen Position befinde, kannst Du da nun draufklicken und mehr erfahren. Aber wer bist Du eigentlich?`
  ],
  disbaleCharacterMoving: false
};

export default (
  state = initState,
  action
) => {
  switch (action.type) {
    case CHANGE_PHRASE:
      return { ...state, currentAvatarPhrase: action.payload };
    case DISABLE_CHARACTER_MOVING:
      return { ...state, disbaleCharacterMoving: action.payload };
    default:
      return state;
  }
};