import React from 'react';
import Game from './components/Game';
import LanguageSelect from './components/languageChoose/LanguageSelect';

function App() {
  const [startGame, setStartGame] = React.useState(false);

  return (
    <div className="game-container">
      {!startGame ? <LanguageSelect startGameHandler={setStartGame} /> : <Game />}
    </div>
  );
}

export default App;
