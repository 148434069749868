import { BP_PICK_OBJ, SET_AVATAR_XY } from "../types";

const initState = {
  avatar: { x: 2461, y: 1583, onboardingX: 2315, onboardingY: 1800, name: 'avatarOp' },
  objects: {
    appendix: { x: 750, y: 935, taken: false, name: 'appendix' },
    animal: {
      x: 1785, y: 1288, onboardingX: 1895, onboardingY: 1675, taken: false, name: 'animal',
      polygonForColission: [1901, 1675, 1926, 1675, 1968, 1657, 2016, 1667,
        2045, 1682, 2064, 1698, 2093, 1730, 2154, 1790, 2133, 1803,
        2049, 1773, 2024, 1753, 2012, 1761, 1926, 1736, 1933, 1721,
        1887, 1707, 1893, 1690]
      ,
      polygonForObject: [1899, 1690, 1903, 1688, 1922, 1688, 1939, 1680, 1958, 1673, 1974, 1673,
        1993, 1675, 2010, 1686, 2027, 1696, 2041, 1707, 2049, 1719, 2062, 1730, 2070, 1740,
        2089, 1750, 2106, 1759, 2123, 1769, 2135, 1782, 2145, 1794, 2135, 1796, 2114, 1784,
        2100, 1776, 2070, 1767, 2054, 1761, 2035, 1744, 2016, 1738, 2008, 1750, 1987, 1746,
        1995, 1734, 1960, 1717, 1949, 1738, 1931, 1732, 1935, 1713, 1899, 1696]
    },
    gorillahand: { x: 1888, y: 1822, taken: false, name: 'gorillahand' },
    shirt: { x: 1122, y: 734, taken: false, name: 'shirt' }
  },
  emptyObjs: [
    {
      x: 860, y: 1065, shadowY: 1251, name: 'emptyObject1', lines: [{ x1: 943, y1: 0, x2: 943, y2: 1110 }],
      collision: []
    },
    {
      x: 1767, y: 1249, shadowY: 1435, name: 'emptyObject1', lines: [{ x1: 1850, y1: 0, x2: 1850, y2: 1295 }],
      collision: []
    },
    {
      x: 1415, y: 1190, shadowY: 1371, name: 'emptyObject2', lines: [{ x1: 1477, y1: 0, x2: 1477, y2: 1230 }],
      collision: []
    },
    {
      x: 1235, y: 825, shadowY: 1072, name: 'emptyObject3', lines: [{ x1: 1283, y1: 0, x2: 1283, y2: 862 }],
      collision: []
    },
  ],
  columns: [
    {
      x: 1226, y: 475, name: 'column', points: [1218, 965, 1246, 947, 1277, 958, 1246, 980,],
      collision: { x: 1247, y: 963, width: 41, height: 18 }
    },
    {
      x: 1684, y: 735, name: 'column', points: [1668, 1228, 1699, 1245, 1734, 1226, 1704, 1202,],
      collision: { x: 1705, y: 1223, width: 41, height: 18 }
    },
    {
      x: 2133, y: 995, name: 'column', points: [2121, 1487, 2154, 1460, 2189, 1474, 2163, 1500,],
      collision: { x: 2154, y: 1483, width: 41, height: 18 }
    }
  ],
  hints: [
    {
      x: 2023, y: 1398, shadowY: 1587, name: 'hintAnimal', for: 'animal', lines: [{ x1: 2112, y1: 0, x2: 2112, y2: 1498 }],
      hintPoints: [2087, 1514, 2089, 1495, 2129, 1483, 2128, 1504]
    },
    {
      x: 1700, y: 1120, shadowY: 1301, name: 'hintRevolutionary', for: 'revolutionary', lines: [{ x1: 1750, y1: 0, x2: 1750, y2: 1144 }],
      hintPoints: [1721, 1174, 1768, 1170, 1768, 1192, 1719, 1199]
    },
    {
      x: 1060, y: 970, shadowY: 1151, name: 'hintAppendix', for: 'appendix', lines: [{ x1: 1104, y1: 0, x2: 1104, y2: 1023 }],
      hintPoints: [1071, 1031, 1126, 1005, 1135, 1032, 1076, 1059]
    },
    {
      x: 1130, y: 1175, shadowY: 1360, name: 'hintShirt', for: 'shirt', lines: [{ x1: 1215, y1: 0, x2: 1215, y2: 1245 }],
      hintPoints: [1199, 1245, 1226, 1238, 1228, 1251, 1201, 1258]
    },
    {
      x: 1643, y: 1480, shadowY: 1657, name: 'hintBird', for: 'bird', lines: [{ x1: 1744, y1: 0, x2: 1744, y2: 1532 },
      { x1: 1774, y1: 0, x2: 1774, y2: 1668 }],
      hintPoints: [1741, 1675, 1797, 1651, 1806, 1680, 1750, 1704]
    },
    {
      x: 1395, y: 1380, shadowY: 1560, name: 'hintGorillahand', for: 'gorillahand', lines: [{ x1: 1440, y1: 0, x2: 1440, y2: 1445 }],
      hintPoints: [1405, 1443, 1469, 1435, 1469, 1466, 1405, 1474]

    },
    {
      x: 1303, y: 875, name: 'hintPaint', for: 'paint', lines: [{ x1: 1336, y1: 0, x2: 1336, y2: 893 }],
      hintPoints: [1311, 965, 1300, 898, 1361, 873, 1370, 934]
    }
  ],
  infoTerminals: [
    { x: 846, y: 835, name: 'terminal1', index: 1 },

    { x: 2545, y: 1473, activeX: 2575, activeY: 1480, name: 'terminal2', index: 0 },

    {
      x: 2040, y: 1950, activeX: 2045, activeY: 1955, helperSize: 60, collision: [2004, 2048, 2060, 2010, 2113, 2046, 2058, 2089],
      name: 'terminal3', index: 2
    },
  ]
};

export default (
  state = initState,
  action
) => {
  switch (action.type) {
    case BP_PICK_OBJ:
      return {
        ...state, objects: {
          ...state.objects, [action.payload.name]: {
            ...state.objects[action.payload.name],
            taken: action.payload.taken
          }
        }
      };
    case SET_AVATAR_XY:
      return { ...state, avatar: { ...state.avatar, ...action.payload } };
    default:
      return state;
  }
};