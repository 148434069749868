import Phaser from 'phaser';
import store from '../../store';
import { dpBgcolor } from '../utils/colors';
import eventEmitter from '../utils/event-emitter';

class EndGame extends Phaser.Scene {
    constructor() {
        super({ key: "endOfGame" });

        this.gameWorld = {};
        this.gameWorld.avatar = null;
        this.gameWorld.doFade = false;
        this.gameWorld.onceFade = true;
        this.gameWorld.bgMusicPlaying = false;
        this.gameWorld.notPlayBgMusic = false;
        this.gameWorld.endOptions = {
            newGame: ['Start the game from the beginning.', 'Das Spiel von vorne starten.'],
            continue: ['Return to the game.', 'Zum Spiel zurückkehren.'],
        }
    }

    create() {
        this.createBackCameraAvatar();
        this.playBgSound();
        this.startChat();
        this.endChat();
    }

    stopDpSoundBg() {
        this.gameWorld.bgMusic.pause();
        this.gameWorld.bgMusic.destroy();
        this.gameWorld.bgMusic = null;
    }

    playBgSound() {
        if (this.gameWorld.bgMusicPlaying === false) {
            this.gameWorld.bgMusic = this.sound.add('SoundDP', { volume: 0.4, loop: true });
            const dpScene = this.scene.get('dproom');
            if (!this.gameWorld.notPlayBgMusic && !dpScene.gameWorld.bgMusic.isPlaying) {
                this.gameWorld.bgMusic.play();
            }
            this.gameWorld.bgMusicPlaying = true;
        }
    }

    playButtonPressedSound() {
        if (!this.gameWorld.buttonPressedSound) {
            this.gameWorld.buttonPressedSound = this.sound.add('btnClickedSound', { volume: 1 });
            this.gameWorld.buttonPressedSound.play();
        }
        else {
            this.gameWorld.buttonPressedSound.play();
        }
    }

    createBackCameraAvatar() {
        this.gameWorld.background = this.add.image(0, 0, 'wall').setOrigin(0, 0);
        this.gameWorld.avatar = this.physics.add.image(944, 950, 'avatarDp').setOrigin(0.5, 0.75).setDepth(3);
        this.gameWorld.avatar.setTexture('avatarDp', 0);

        this.cameras.main.setBounds(0, 0, 1920, 1200, true);
        this.cameras.main.startFollow(this.gameWorld.avatar);
        this.cameras.main.setBackgroundColor(dpBgcolor);
        this.cameras.main.fadeIn(1000, 201, 52, 126);
    }

    showInteractiveChat(x, y) {
        this.interactiveChatDOM = document.getElementById("end-chat");
        this.interactiveChatDOM.addEventListener('click', this.onInteractiveChatClick.bind(this))
        this.gameWorld.interactiveChat = this.add.dom(x + 35, y - 450, this.interactiveChatDOM, {
            'pointer-events': 'auto'
        }).setOrigin(0, 0);
        this.animateAppear(this.gameWorld.interactiveChat);
    }

    onInteractiveChatClick(event) {
        if (event.composedPath()[0].tagName === 'BUTTON') {
            this.playButtonPressedSound();
            const { endOptions } = this.gameWorld;
            if (endOptions.newGame.includes(event.composedPath()[0].innerHTML)) {
                this.stopDpSoundBg();
                window.location.reload();
            }
            else if (endOptions.continue.includes(event.composedPath()[0].innerHTML)) {
                this.stopDpSoundBg();
                const { coreGameplay: { runEndOfGamFrom } } = store.getState();
                if (runEndOfGamFrom && runEndOfGamFrom === 'bgRoom') {
                    this.scene.switch('bigroom');
                    const onboardingScene = this.scene.get('bigroomOnboarding');
                    onboardingScene.gameWorld.bgMusic.resume();
                }
                else if (runEndOfGamFrom && runEndOfGamFrom === 'dpRoom') {
                    this.scene.switch('dproom');
                }
            }
        }
    }

    createImpressum() {
        const { language: { choosenLanguage } } = store.getState();
        if (choosenLanguage === 'en') {
            this.gameWorld.impressum = this.add.dom(100, 50).createFromCache('enImpressum').setOrigin(0, 0);
        }
        else if (choosenLanguage === 'de') {
            this.gameWorld.impressum = this.add.dom(100, 50).createFromCache('deImpressum').setOrigin(0, 0);
        }
        this.animateAppear(this.gameWorld.impressum);
    }

    closeInteractiveChat() {
        this.gameWorld.interactiveChat.destroy();
        this.gameWorld.interactiveChat = null;
    }

    startChat() {
        setTimeout(() => {
            this.showInteractiveChat(1010, 710);
            this.createImpressum();
        }, 1000)
    }

    endChat() {
        eventEmitter.on("CLOSE_END_CHAT", () => {
            this.interactiveChatDOM.removeEventListener('click', this.onInteractiveChatClick);
            this.interactiveChatDOM = null;
            new Promise(resolve => {
                setTimeout(() => {
                    const interactiveChat = document.getElementById("end-chat");
                    interactiveChat.style.display = 'none';
                    this.closeInteractiveChat();
                    resolve();
                }, 500);
            });
        });
    }

    animateAppear(target) {
        this.tweens.add({
            targets: target,
            alpha: { from: 0, to: 1 },
            ease: 'Cubic.easeOut',
            duration: 1000,
            yoyo: false
        });
    }
}

export default EndGame;