import Phaser from 'phaser';
import store from '../../store';
import { changePhrase, setWatchedGuides, setVideoPlaying } from '../../store/actions';
import { inventroyVideoBgColor } from '../utils/colors';

class InventoryUI extends Phaser.Scene {
    constructor() {
        super({ key: "inventoryUI" });
        this.gameState = {};
        this.gameState.emptySlots = [];
        this.gameState.inventoryX = 0;
        this.gameState.inventoryY = 0;
        this.gameState.terminals = new Set();
    }

    preload() {
        this.preloadInventoryObjects();
        this.preloadVideos();
        this.preloadBgForVideos();
        this.preloadConfetti();
    }

    create() {
        this.createInventoryUI();
        this.createInventoryEmptySlotsUI();
        this.createConfetti();
    }

    confettiAnim() {
        this.anims.create({
            key: "confetti",
            frameRate: 15,
            frames: this.anims.generateFrameNumbers("confetti", { start: 0, end: 14 }),
            repeat: -1
        });
        this.gameState.confettiRain.play('confetti');
    }

    startHandAnim() {
        this.anims.create({
            key: "handAnim",
            frameRate: 24,
            frames: this.anims.generateFrameNumbers("handAnim", { start: 0, end: 23 }),
            repeat: 0
        });

        const x = this.cameras.main.worldView.right - 684 - 50; // 20
        const y = this.cameras.main.worldView.bottom - 165 - 50; // 20

        this.gameState.startHandAnim = this.add.sprite(x, y, 'handAnim');
        this.gameState.startHandAnim.play("handAnim");

        this.gameState.startHandAnim.on('animationcomplete', (anim, frame) => {
            this.gameState.startHandAnim.destroy()
            this.gameState.startHandAnim = null;
            const onboardingRoom = this.scene.get('bigroomOnboarding');
            onboardingRoom.handMoveAnim();
        }, this.gameState.startHandAnim);
    }

    update() {
        if (this.needToUpdate) {
            this.updateItemsPosition();
        }

        if (this.flying) {
            this.checkEndOfFlying();
        }
    }

    preloadConfetti() {
        const h = this.cameras.main.height / 2;
        const w = this.cameras.main.width / 2;
        this.load.spritesheet("confetti", "assets/confettiAnimation.png", { frameWidth: w, frameHeight: h });
    }

    preloadInventoryObjects() {
        const { language: { choosenLanguage } } = store.getState();
        if (choosenLanguage === 'en') {
            this.load.image('inventoryBg', 'assets/Inventory/inventoryBackgroundEn.png');
        }
        else if (choosenLanguage === 'de') {
            this.load.image('inventoryBg', 'assets/Inventory/inventoryBackgroundDe.png');
        }
        this.load.image('emptySlot', 'assets/Inventory/emptySlot.png');

        this.load.image('playbuttonInactiv', 'assets/Inventory/objects/inventoryPlaybuttonInactiv.png');
        this.load.image('playbuttonActiv', 'assets/Inventory/objects/inventoryPlaybuttonActiv.png');
        this.load.image('closeButton', 'assets/Inventory/objects/inventoryCloseButton.png');

        this.load.image('paintInactiv', 'assets/Inventory/objects/inventoryPaintingInactiv.png');
        this.load.image('paintActiv', 'assets/Inventory/objects/inventoryPaintingActiv.png');

        this.load.image('birdInactiv', 'assets/Inventory/objects/inventoryBirdInactiv.png');
        this.load.image('birdActiv', 'assets/Inventory/objects/inventoryBirdActiv.png');

        this.load.image('animalInactiv', 'assets/Inventory/objects/inventoryAnimalInactiv.png');
        this.load.image('animalActiv', 'assets/Inventory/objects/inventoryAnimalActiv.png');

        this.load.image('appendixInactiv', 'assets/Inventory/objects/inventoryAppendixInactiv.png');
        this.load.image('appendixActiv', 'assets/Inventory/objects/inventoryAppendixActiv.png');

        this.load.image('gorillahandInactiv', 'assets/Inventory/objects/inventoryGorillahandInactiv.png');
        this.load.image('gorillahandActiv', 'assets/Inventory/objects/inventoryGorillahandActiv.png');

        this.load.image('revolutionaryInactiv', 'assets/Inventory/objects/inventoryRevolutionaryInactiv.png');
        this.load.image('revolutionaryActiv', 'assets/Inventory/objects/inventoryRevolutionaryActiv.png');

        this.load.image('shirtInactiv', 'assets/Inventory/objects/inventoryShirtInactiv.png');
        this.load.image('shirtActiv', 'assets/Inventory/objects/inventoryShirtActiv.png');

        this.load.image('terminal1Inactiv', 'assets/Inventory/objects/inventoryTerminal1Inactiv.png');
        this.load.image('terminal1Activ', 'assets/Inventory/objects/inventoryTerminal1Activ.png');

        this.load.image('terminal2Inactiv', 'assets/Inventory/objects/inventoryTerminal2Inactiv.png');
        this.load.image('terminal2Activ', 'assets/Inventory/objects/inventoryTermina2Activ.png');

        this.load.image('terminal3Inactiv', 'assets/Inventory/objects/inventoryTerminal3Inactiv.png');
        this.load.image('terminal3Activ', 'assets/Inventory/objects/inventoryTerminal3Activ.png');
    }

    preloadVideos() {
        const { language: { choosenLanguage } } = store.getState();
        this.load.html('animalVideo', `assets/Inventory/videos/${choosenLanguage}/animalVideo.html`);
        this.load.html('birdVideo', `assets/Inventory/videos/${choosenLanguage}/birdVideo.html`);
        this.load.html('paintVideo', `assets/Inventory/videos/${choosenLanguage}/paintVideo.html`);
        this.load.html('appendixVideo', `assets/Inventory/videos/${choosenLanguage}/appendixVideo.html`);
        this.load.html('gorillahandVideo', `assets/Inventory/videos/${choosenLanguage}/gorillahandVideo.html`);
        this.load.html('revolutionaryVideo', `assets/Inventory/videos/${choosenLanguage}/revolutionaryVideo.html`);
        this.load.html('shirtVideo', `assets/Inventory/videos/${choosenLanguage}/shirtVideo.html`);
        this.load.html('terminal1Video', `assets/Inventory/videos/${choosenLanguage}/terminal1Video.html`);
        this.load.html('terminal2Video', `assets/Inventory/videos/${choosenLanguage}/terminal2Video.html`);
        this.load.html('terminal3Video', `assets/Inventory/videos/${choosenLanguage}/terminal3Video.html`);
    }

    preloadBgForVideos() {
        this.load.image('revolutionaryBgForVideo', 'assets/Inventory/videoBackgrounds/revolutionary.png');
        this.load.image('shirtBgForVideo', 'assets/Inventory/videoBackgrounds/shirt.png');
        this.load.image('birdBgForVideo', 'assets/Inventory/videoBackgrounds/bird.png');
        this.load.image('gorillahandBgForVideo', 'assets/Inventory/videoBackgrounds/gorillahand.png');
        this.load.image('animalBgForVideo', 'assets/Inventory/videoBackgrounds/animal.png');
        this.load.image('appendixBgForVideo', 'assets/Inventory/videoBackgrounds/appendix.png');
        this.load.image('paintBgForVideo', 'assets/Inventory/videoBackgrounds/paint.png');
        this.load.image('terminal1BgForVideo', 'assets/Inventory/videoBackgrounds/infoTerminal1.png');
        this.load.image('terminal2BgForVideo', 'assets/Inventory/videoBackgrounds/infoTerminal2.png');
        this.load.image('terminal3BgForVideo', 'assets/Inventory/videoBackgrounds/infoTerminal3.png');
    }

    createConfetti() {
        this.gameState.confettiRain = this.add.sprite(0, 0, "confetti").setOrigin(0, 0).setDepth(5)
            .setVisible(false).setScale(2);
    }

    createInventoryUI() {
        this.gameState.inventoryX = this.cameras.main.worldView.right - 942;
        this.gameState.inventoryY = this.cameras.main.worldView.bottom - 248;
        this.gameState.inventoryUI = this.add.image(this.gameState.inventoryX, this.gameState.inventoryY, 'inventoryBg').setOrigin(0, 0);

        this.gameState.inventoryUI.setScrollFactor(1, 1);
        this.animateAppear(this.gameState.inventoryUI);
        this.cameras.main.setBounds(0, 0, 1920, 1200, true);
    }

    createInventoryEmptySlotsUI() {
        const { emptySlots } = this.gameState;
        for (let i = 0; i < 10; i += 1) {
            let emptySlotX = this.gameState.inventoryX + 20;
            const emptySlotY = this.gameState.inventoryY + 65;
            if (i === 1) {
                const prevSlot = emptySlots[i - 1];
                emptySlotX = prevSlot.x + (prevSlot.width + 10);
            }
            else if (i > 1) {
                const prevSlot = emptySlots[i - 1];
                emptySlotX = prevSlot.x + (prevSlot.width + 12);
            }

            const emptySlot = this.add.image(emptySlotX, emptySlotY, 'emptySlot').setOrigin(0, 0);
            emptySlots.push(emptySlot);
        }
    }

    createVideo(name, x, y) {
        if (this.speechBubbleToRemove) {
            this.speechBubbleToRemove.destroy();
        }

        this.children.list.forEach(element => {
            if (element.node) {
                this.destroyAllVideoElemets(element.name);
            }
        });

        if (!this[`${name}Video`]) {
            this[`${name}VideoBg`] = this.add.image(x, y, `${name}BgForVideo`)
                .setOrigin(1, 1).setDepth(4).setScale(1.2);

            const backgroundSquareX = (this[`${name}VideoBg`].x - (this[`${name}VideoBg`].width - 65)) - 150;
            let backgroundYOffset;
            switch (name) {
                case 'gorillahand':
                    backgroundYOffset = 270;
                    break;
                case 'animal':
                    backgroundYOffset = 20;
                    break;
                case 'bird':
                    backgroundYOffset = 210;
                    break;
                case 'paint':
                    backgroundYOffset = 100;
                    break;
                case 'appendix':
                    backgroundYOffset = 250;
                    break;
                case 'revolutionary':
                    backgroundYOffset = 50;
                    break;
                case 'shirt':
                    backgroundYOffset = 120;
                    break;
                case 'terminal1':
                    backgroundYOffset = 100;
                    break;
                case 'terminal2':
                    backgroundYOffset = 60;
                    break;
                case 'terminal3':
                    backgroundYOffset = 120;
                    break;
                default:
                    break;
            }
            const backgroundSquareY = (this[`${name}VideoBg`].y - (this[`${name}VideoBg`].height - backgroundYOffset)) / 1.16;

            const backgroundSquarePadding = 15;
            const backgroundSquareWidth = 500;
            const backgroundSquareHeight = 340;

            this[`${name}backgroundSquare`] = this.add.graphics({ x: backgroundSquareX, y: backgroundSquareY }).setDepth(4).setScale(1.2);
            this[`${name}backgroundSquare`].fillStyle(inventroyVideoBgColor, 1);
            this[`${name}backgroundSquare`].lineStyle(4, inventroyVideoBgColor, 1);
            this[`${name}backgroundSquare`].fillRect(0, 0, backgroundSquareWidth, backgroundSquareHeight);

            const videoX = backgroundSquareX + backgroundSquarePadding + 3;
            const videoY = backgroundSquareY + backgroundSquarePadding + 3;
            const videoWidth = backgroundSquareWidth - 2 * backgroundSquarePadding;
            const videoHeight = backgroundSquareHeight - 2 * backgroundSquarePadding;

            this[`${name}Video`] = this.add.dom(videoX, videoY).createFromCache(`${name}Video`).setOrigin(0, 0).setScale(1.2);
            this[`${name}Video`].node.firstChild.firstElementChild.width = videoWidth;
            this[`${name}Video`].node.firstChild.firstElementChild.height = videoHeight;
            this[`${name}Video`].type = 'video';
            this[`${name}Video`].name = name;

            const onboardingScene = this.scene.get('bigroomOnboarding');
            const dpRoomScene = this.scene.get('dproom');
            store.dispatch(setVideoPlaying(true));
            onboardingScene.changeBgMusicVolume();
            dpRoomScene.changeBgMusicVolume();

            this[`${name}closeVideoButton`] = this.add.image((videoX + 95 + videoWidth + 35 / 2),
                videoY - 35 / 2, 'closeButton')
                .setDepth(4).setScale(1.2);

            this[`${name}closeVideoButton`].setInteractive({ useHandCursor: true });
            const { onBoarding: { currentAvatarPhrase } } = store.getState();
            this.animateAppear(this[`${name}VideoBg`], this[`${name}backgroundSquare`], this[`${name}Video`]);
            this[`${name}closeVideoButton`].on('pointerdown', () => {
                store.dispatch(setVideoPlaying(false));
                onboardingScene.changeBgMusicVolume();
                dpRoomScene.changeBgMusicVolume();

                this.destroyAllVideoElemets(name);
                if (name === 'animal' && currentAvatarPhrase === 3) {
                    onboardingScene.cameras.main.fadeOut(500, 201, 52, 126);
                    onboardingScene.cameras.main.once('camerafadeoutcomplete', () => {
                        onboardingScene.stopWalkingSound();
                        store.dispatch(changePhrase(null));
                        this.scene.launch('bigroom');
                        this.scene.sleep('bigroomOnboarding');
                    });
                }

                if (name.slice(0, -1) === 'terminal') {
                    const prevSize = this.gameState.terminals.size;
                    this.gameState.terminals.add(name);
                    if (prevSize !== this.gameState.terminals.size) {
                        store.dispatch(setWatchedGuides());
                    }
                }
            })
        }
    }

    destroyAllVideoElemets(name) {
        this[`${name}Video`].destroy();
        this[`${name}Video`] = null;
        this[`${name}VideoBg`].destroy();
        this[`${name}VideoBg`] = null;
        this[`${name}backgroundSquare`].destroy();
        this[`${name}backgroundSquare`] = null;
        this[`${name}closeVideoButton`].destroy();
        this[`${name}closeVideoButton`] = null;
    }

    updateItemsPosition() {
        const { Inventory: { objects } } = store.getState();
        const { emptySlots } = this.gameState;
        objects.forEach((element, index) => {
            const elementUi = this.scene.scene.children.list.find(elem => elem.name === element.name);
            const playButtonUi = this.scene.scene.children.list.find(elem => elem.forObject === element.name);

            const playButtonX = emptySlots[index].x + 35;
            const playButtonY = emptySlots[index].y + 35;
            playButtonUi.setDepth(3);
            playButtonUi;
            playButtonUi.x = playButtonX;
            playButtonUi.y = playButtonY;
            playButtonUi.type = 'playButton';

            elementUi.setOrigin(0, 0);
            elementUi;

            switch (elementUi.name) {
                case 'gorillahand':
                    elementUi.x = emptySlots[index].x + 15;
                    elementUi.y = emptySlots[index].y - 15;
                    break;
                case 'animal':
                    elementUi.x = emptySlots[index].x + 4;
                    elementUi.y = emptySlots[index].y + 10;
                    break;
                case 'appendix':
                    elementUi.x = emptySlots[index].x + 9;
                    elementUi.y = emptySlots[index].y - 35;
                    break;
                case 'bird':
                    elementUi.x = emptySlots[index].x + 5;
                    elementUi.y = emptySlots[index].y - 40;
                    break;
                case 'paint':
                    elementUi.x = emptySlots[index].x + 5;
                    elementUi.y = emptySlots[index].y - 10;
                    break;
                case 'revolutionary':
                    elementUi.x = emptySlots[index].x + 3;
                    elementUi.y = emptySlots[index].y;
                    break;
                case 'shirt':
                    elementUi.x = emptySlots[index].x + 5;
                    elementUi.y = emptySlots[index].y - 15;
                    break;
                case 'terminal1':
                    elementUi.x = emptySlots[index].x + 20;
                    elementUi.y = emptySlots[index].y - 50;
                    break;
                case 'terminal2':
                    elementUi.x = emptySlots[index].x + 17;
                    elementUi.y = emptySlots[index].y - 42;
                    break;
                case 'terminal3':
                    elementUi.x = emptySlots[index].x + 7;
                    elementUi.y = emptySlots[index].y - 52;
                    break;
                default:
                    break;
            }
            if (element.objectToDrag.inInventory) {
                element.objectToDrag.object;
                element.objectToDrag.object.x = elementUi.x;
                element.objectToDrag.object.y = elementUi.y;
            }
            if (element.correctPosition && !this.flying) {
                if (element.name.slice(0, -1) === 'terminal') {
                    elementUi.setDepth(2);
                }
                elementUi.setTexture && elementUi.setTexture(`${element.name}Activ`);
                playButtonUi.setTexture('playbuttonActiv');
                playButtonUi.setInteractive({ useHandCursor: true });
                playButtonUi.off('pointerdown');
                playButtonUi.on('pointerdown', () => {
                    const { x, y, width, height } = emptySlots[index];
                    if (elementUi.name === 'animal') {
                        this.createVideo(elementUi.name, x + (2 * width) + 7,
                            y + height);
                    } else {
                        this.createVideo(elementUi.name, x + width,
                            y + height);
                    }
                })
            }
        });
        this.needToUpdate = false;
    }

    checkEndOfFlying() {
        const items = ['animal', 'bird', 'appendix', 'gorillahand', 'revolutionary',
            'shirt', 'paint', 'terminal1', 'terminal2', 'terminal3'];
        items.forEach((itemName) => {
            if (this[`${itemName}FlyingItem`]) {
                this.endFlyToInventory(itemName);
            }
        })
        if (items.every(itemName => !this[`${itemName}FlyingItem`])) {
            this.flying = false;
        }
    }

    endFlyToInventory(itemName) {
        const distance = Phaser.Math.Distance.Between(this[`${itemName}FlyingItem`].x, this[`${itemName}FlyingItem`].y,
            this[`${itemName}FlyingItem`].finalX, this[`${itemName}FlyingItem`].finalY);
        if (this[`${itemName}FlyingItem`].body.speed > 0 && distance < 50) {
            this[`${itemName}FlyingItem`].destroy();
            this[`${itemName}FlyingItem`] = null;
            this.needToUpdate = true;

            this.objToAdd.setVisible(true);
            this.objToAddCopy.setVisible(true);
            this.playButtonToAdd.setVisible(true);
        }
    }

    flyToInventory(itemName) {
        const { emptySlots } = this.gameState;
        const { Inventory: { objects } } = store.getState();
        const { centerX, centerY } = this.cameras.main;
        const { x, y } = emptySlots[objects.length - 1];
        this.flyEffect(itemName, { x, y }, { x: centerX, y: centerY });
    }

    flybackToInventory(itemName) {
        const { x, y } = this.cameras.main.getWorldPoint(this.input.activePointer.x, this.input.activePointer.y);
        const objectPrototype = this.children.list.find(elem => elem.name === itemName);
        this.flyEffect(itemName, objectPrototype, { x, y });
    }

    flyEffect(itemName, objectPrototype, { x, y }) {
        this.flying = true;
        const objectToFly = this.add.image(x, y, `${itemName}Inactiv`).setOrigin(0, 0)
            ;
        this.physics.add.existing(objectToFly);
        objectToFly.finalX = objectPrototype.x;
        objectToFly.finalY = objectPrototype.y;
        this.physics.moveToObject(objectToFly, { x: objectToFly.finalX, y: objectToFly.finalY }, 740);
        this[`${itemName}FlyingItem`] = objectToFly;
    }

    flickPlayButton(itemName) {
        const playButtonUi = this.scene.scene.children.list.find(elem => elem.forObject === itemName);
        this.tweens.add({
            targets: playButtonUi,
            alpha: 0.7,
            scale: 1.4,
            ease: 'Cubic.easeOut',
            duration: 300,
            repeat: 4,
            yoyo: true
        })
    }

    animateAppear(...targets) {
        this.tweens.add({
            targets,
            alpha: { from: 0, to: 1 },
            ease: 'Cubic.easeOut',
            duration: 1000,
            yoyo: false
        })
    }
}

export default InventoryUI;