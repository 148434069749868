export default `0 Oh je, oh je, oh je... [2,3]
2 Was ist los? -> 4
3 Alles ok? -> 4
4 Wir Ausstellungsstücke wurden hier einfach so hineingestellt. -> 5
5 Dabei hat jedes einen ganz klaren Platz. -> 6
6 Kannst Du mir bitte helfen? [7,8]
7 Ja. -> 9
8 Ich kann es gerne versuchen! -> 9
9 Toll! -> 10
10 In den Vitrinen hängen Zettel mit Hinweisen, welches Ausstellungsstück hinein gehört. -> 11
11 Zuerst musst Du die Ausstellungstücke aber einsammeln.. -> 12
12 Und bitte beeil Dich, die Gäste stehen schon vor der Tür... -> 13
13 Kannst du mich bitte als erstes einsammeln? [14,15]
14 Klaro! -> 16
15 Gerne, lass uns loslegen. -> 16
16 Super. -> 17
17 Du schaffst das! -> 18
18 🤓🤓🥰 -> 10001

10001 ||Exit
`