import Phaser from 'phaser';
import React from 'react';
import ReactDOM from 'react-dom';
import store from '../../store';
import { inventoryAddCopyOfObject, inventoryPickObj, setRunEndOfGame } from '../../store/actions';
import Chat from '../interactive-chat/Chat';
import { dpBgcolor, portalsColor } from '../utils/colors';
import makeDraggableScene from '../utils/dragAndDrop';

class DPRoom extends Phaser.Scene {
    constructor() {
        super({ key: "dproom" });

        this.gameWorld = {};
        this.gameWorld.avatar = null;
        this.gameWorld.vector = new Phaser.Math.Vector2();
        this.gameWorld.walkingAvailableArea = null;
        this.gameWorld.portal = null;
        this.gameWorld.previousCoordinates = {};
        this.gameWorld.hideObjectX = -1000;
        this.gameWorld.paintTaken = false;
        this.gameWorld.birdTaken = false;
        this.gameWorld.revolutionaryTaken = false;
        this.gameWorld.doFade = true;
        this.gameWorld.bgMusicPlaying = false;
        this.gameWorld.walkMusicPlaying = false;
        this.gameWorld.runEndOfGame = true;
    }

    preload() {
        this.setBackgroundColor();
        this.setFadeIn();
    }

    create() {
        this.createBackCameraAvatar();
        this.createObjects();
        this.createWalkingArea();
        this.detectPointerDown();
        this.detectAvatarOverlapItem();
        this.createPortals();

        makeDraggableScene(this, 'inventoryUI');
        this.sceneWake();

        this.pauseOpSoundBgAndRunDpSoundBg();
    }

    update() {
        this.checkAvatarCoordinates();
        this.returnItemToInventoryUI();
        this.runEndOfGame();
    }

    changeBgMusicVolume() {
        const { Inventory: { videoPlaying } } = store.getState();
        if (videoPlaying && this.gameWorld.bgMusic) {
            this.gameWorld.bgMusic.setVolume(0.02);
        }
        if (!videoPlaying && this.gameWorld.bgMusic) {
            this.gameWorld.bgMusic.setVolume(0.3);
        }
    }

    setFadeIn() {
        this.cameras.main.fadeIn(500, 201, 52, 126);
    }

    setBackgroundColor() {
        this.cameras.main.setBackgroundColor(dpBgcolor);
    }

    pauseOpSoundBgAndRunDpSoundBg() {
        this.scene.get('bigroom').stopWalkingSound();
        const onboardingScene = this.scene.get('bigroomOnboarding');
        onboardingScene.gameWorld.bgMusic.pause();
        this.playBgSound();
    }

    pauseDpSoundBgAndRunOPSoundBg() {
        this.gameWorld.bgMusic.pause();
        const onboardingScene = this.scene.get('bigroomOnboarding');
        onboardingScene.gameWorld.bgMusic.resume();
    }

    playSound(soundName) {
        const { Inventory: { videoPlaying } } = store.getState();
        const volume = videoPlaying ? 0.01 : 1;
        if (!this.gameWorld[`${soundName}Music`]) {
            if (soundName === 'walk') {
                this.gameWorld[`${soundName}Music`] = this.sound.add(`${soundName}Sound`, { volume, loop: true });
                this.gameWorld.walkMusicPlaying = true;
            }
            else {
                this.gameWorld[`${soundName}Music`] = this.sound.add(`${soundName}Sound`, { volume });
            }
            this.gameWorld[`${soundName}Music`].play();
        }
        else {
            this.gameWorld[`${soundName}Music`].play({ volume });
        }
    }

    playBgSound() {
        if (this.gameWorld.bgMusicPlaying === false) {
            this.gameWorld.bgMusic = this.sound.add('SoundDP', { volume: 0.2, loop: true });
            this.gameWorld.bgMusic.play();
            this.gameWorld.bgMusicPlaying = true;
            this.changeBgMusicVolume();
        }
        else {
            this.gameWorld.bgMusic.resume();
        }
    }

    stopWalkingSound() {
        if (this.gameWorld.walkMusic) {
            this.gameWorld.walkMusic.stop();
            this.gameWorld.walkMusicPlaying = false;
            this.gameWorld.walkMusic = null;
        }
    }

    createBackCameraAvatar() {

        let bgName = 'wall';
        let avatarX = 242;
        let avatarY = 1105;
        if (this.registry.get('dpBg') === 'dp3') {
            bgName = 'dp3background';
            avatarX = 200;
            avatarY = 850;
        }
        this.gameWorld.background = this.add.image(0, 0, bgName).setOrigin(0, 0);
        this.gameWorld.avatar = this.physics.add.image(avatarX, avatarY, 'avatarDp').setOrigin(0.5, 0.75).setDepth(3);
        this.gameWorld.avatar.setTexture('avatarDp', 0);

        this.cameras.main.startFollow(this.gameWorld.avatar);
        this.cameras.main.setBounds(0, 0, 1920, 1200, true);
    }

    createObjects() {
        this.gameWorld.bg = this.registry.get('dpBg');
        switch (this.gameWorld.bg) {
            case 'dp1':
                this.gameWorld.item = this.physics.add.staticImage(600, 556, 'paint').setOrigin(0, 0).refreshBody();
                this.gameWorld.item.name = 'paint';
                break;
            case 'dp2':
                this.gameWorld.item = this.physics.add.staticImage(1080, 706, 'bird').setOrigin(0, 0).refreshBody();
                this.gameWorld.item.name = 'bird';
                break;
            case 'dp3':
                this.gameWorld.item = this.physics.add.staticImage(558, 452, 'revolutionary').setOrigin(0, 0).refreshBody();
                this.gameWorld.item.name = 'revolutionary';
                break;
            default:
                break;
        }
    }

    createPortals() {
        let arrowX = 55;
        let arrowY = 1020;
        this.gameWorld.graphics = this.add.graphics().setDepth(2);
        let portalPoints = [
            56, 1104, 56, 1023, 189, 1021,
            160, 1044, 193, 1062, 124, 1106, 89, 1083
        ];

        if (this.registry.get('dpBg') === 'dp3') {
            portalPoints = [
                38, 714, 36, 604, 220, 611,
                182, 636, 212, 661, 134, 720,
                88, 698, 1549, 792,
            ];
            arrowX = 55;
            arrowY = 620;
        }
        this.gameWorld.portal = new Phaser.Geom.Polygon(portalPoints);
        this.gameWorld.graphics.fillStyle(portalsColor, 0.0);
        this.gameWorld.graphics.fillPoints(this.gameWorld.portal.points, true);

        this.gameWorld.arrowOut = this.add.image(arrowX, arrowY, 'dpArrowOut').setOrigin(0, 0).setDepth(2);
        this.tweens.add({
            targets: this.gameWorld.arrowOut,
            alpha: { from: 0.3, to: 1 },
            ease: 'Cubic.easeOut',
            duration: 300,
            repeat: -1,
            yoyo: true
        })
    }

    createWalkingArea() {
        let areaPoints = [14, 884, 284, 1023, 1087, 680, 1191, 672,
            1455, 828, 1477, 914, 1045, 1245, 6, 1206];
        if (this.registry.get('dpBg') === 'dp3') {
            areaPoints = [0, 488, 485, 770, 760, 705, 1666, 1206,
                1914, 1206, 1914, 1264, 0, 1264];
        }
        this.gameWorld.walkingAvailableArea = new Phaser.Geom.Polygon(areaPoints);
    }

    detectPointerDown() {
        const { avatar, vector } = this.gameWorld;

        this.input.on('pointerdown', (pointer) => {
            const transformedPoint = this.cameras.main.getWorldPoint(pointer.x, pointer.y);
            if (Phaser.Geom.Polygon.Contains(this.gameWorld.walkingAvailableArea, transformedPoint.x, transformedPoint.y)) {
                const angle = Phaser.Math.Angle.BetweenPoints(avatar, transformedPoint) * 180 / Math.PI;

                if (angle < 115 && angle > 45) avatar.setTexture('avatarDp', 0);
                if (angle > 115 || angle < -115) avatar.setTexture('avatarDp', 1);
                if (angle > -45 && angle < 45) avatar.setTexture('avatarDp', 2);
                if (angle < -45 && angle > -115) avatar.setTexture('avatarDp', 3);

                vector.x = transformedPoint.x;
                vector.y = transformedPoint.y;
                this.physics.moveToObject(avatar, vector, 240);
                if (this.gameWorld.walkMusicPlaying === false) {
                    this.playSound('walk');
                    this.gameWorld.walkMusicPlaying = true;
                }
            }
        });
    }

    detectAvatarOverlapItem() {
        const inventoryScene = this.scene.get('inventoryUI');
        this.physics.add.overlap(this.gameWorld.avatar, this.gameWorld.item, (_, item) => {
            item.setActive(false).setVisible(false);
            item.body.enable = false;
            item.refreshBody();

            switch (item.name) {
                case 'paint':
                    this.gameWorld.paintTaken = true;
                    break;
                case 'bird':
                    this.gameWorld.birdTaken = true;
                    break;
                case 'revolutionary':
                    this.gameWorld.revolutionaryTaken = true;
                    break;
                default:
                    break;
            }

            this.playSound('success');
            inventoryScene.objToAdd = inventoryScene.add.image(-1000, 0, `${item.name}Inactiv`).setVisible(false);
            inventoryScene.objToAdd.name = item.name;
            inventoryScene.needToUpdate = true;

            const playButton = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'playbuttonInactiv').setVisible(false);
            playButton.forObject = item.name;
            inventoryScene.playButtonToAdd = playButton;

            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, `${item.name}Inactiv`).setOrigin(0, 0).setVisible(false);
            objectCopy.setInteractive({ useHandCursor: true });
            inventoryScene.objToAddCopy = objectCopy;
            objectCopy.name = item.name;
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true };
            store.dispatch(inventoryPickObj(objectCopy.name, objectToDrag));

            inventoryScene.flyToInventory(objectCopy.name);
        });
    }


    checkAvatarCoordinates() {
        const { avatar, vector, previousCoordinates } = this.gameWorld;
        const distance = Phaser.Math.Distance.Between(avatar.x, avatar.y, vector.x, vector.y);

        if (!Phaser.Geom.Polygon.Contains(this.gameWorld.walkingAvailableArea, avatar.x, avatar.y)) {
            avatar.body.reset(previousCoordinates.x, previousCoordinates.y);
            this.stopWalkingSound();
        } else {
            previousCoordinates.x = avatar.x;
            previousCoordinates.y = avatar.y;
        }

        if (avatar.body.speed > 0 && distance < 10) {
            avatar.body.reset(vector.x, vector.y);
            this.stopWalkingSound();
        }

        if (this.gameWorld.doFade && Phaser.Geom.Polygon.Contains(this.gameWorld.portal, avatar.x, avatar.y)) {
            this.gameWorld.doFade = false;
            this.cameras.main.fadeOut(500, 201, 52, 126);
            this.cameras.main.once('camerafadeoutcomplete', () => {
                this.stopWalkingSound();
                this.scene.switch('bigroom');
                this.gameWorld.avatar.body.reset(242, 1165);
                avatar.setTexture('avatarDp', 0);
                this.clearListeners();
                makeDraggableScene(this.scene.get('bigroom'), 'inventoryUI');
                this.gameWorld.doFade = true;
                this.pauseDpSoundBgAndRunOPSoundBg();
            });

        }
    }

    sceneWake() {
        this.events.on(Phaser.Scenes.Events.WAKE, function () {
            this.scene.get('bigroom').stopWalkingSound();
            this.pauseOpSoundBgAndRunDpSoundBg();
            this.setFadeIn();
            makeDraggableScene(this, 'inventoryUI');
            this.gameWorld.bg = this.registry.get('dpBg');
            let avatarX = 242;
            let avatarY = 1165;
            if (this.gameWorld.bg === 'dp3') {
                avatarX = 200;
                avatarY = 850;
            }
            this.gameWorld.avatar.body.reset(avatarX, avatarY);
            switch (this.gameWorld.bg) {
                case 'dp1':
                    this.gameWorld.background.setTexture('wall');
                    this.createWalkingArea();
                    this.gameWorld.item.destroy();
                    this.gameWorld.graphics.destroy();
                    this.gameWorld.arrowOut.destroy();
                    this.createPortals();

                    if (!this.gameWorld.paintTaken) {
                        this.createObjects();
                        this.detectAvatarOverlapItem();
                    }
                    break;
                case 'dp2':
                    this.gameWorld.background.setTexture('wall');
                    this.createWalkingArea();
                    this.gameWorld.item.destroy();
                    this.gameWorld.graphics.destroy();
                    this.gameWorld.arrowOut.destroy();
                    this.createPortals();

                    if (!this.gameWorld.birdTaken) {
                        this.createObjects();
                        this.detectAvatarOverlapItem();
                    }
                    break;
                case 'dp3':
                    this.gameWorld.background.setTexture('dp3background');
                    this.createWalkingArea();
                    this.gameWorld.item.destroy();
                    this.gameWorld.graphics.destroy();
                    this.gameWorld.arrowOut.destroy();
                    this.createPortals();

                    if (!this.gameWorld.revolutionaryTaken) {
                        this.createObjects();
                        this.detectAvatarOverlapItem();
                    }
                    break;
                default:
                    break;
            };
        }, this);
    }

    clearListeners() {
        this.dragTarget = null;
        this.dragTargetDropped = null;
        const inventoryScene = this.scene.get('inventoryUI');
        inventoryScene.dragTarget = null;
        inventoryScene.dragTargetDropped = null;
        inventoryScene.input.off("pointerdown", null, null);
        inventoryScene.input.off("pointermove", null, null);
        inventoryScene.input.off("pointerup", null, null);
    }

    returnItemToInventoryUI() {
        if (this.dragTargetDropped) {
            const inventoryScene = this.scene.get('inventoryUI');

            this.playSound('fail');
            this.shakeCamera();
            inventoryScene.flybackToInventory(this.dragTarget);

            this.dragTargetDropped.destroy();
            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, `${this.dragTarget}Inactiv`).setOrigin(0, 0);
            objectCopy.setInteractive({ useHandCursor: true });
            objectCopy.name = this.dragTarget;
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true }
            store.dispatch(inventoryAddCopyOfObject(objectCopy.name, objectToDrag));
            inventoryScene.needToUpdate = true;

            this.dragTarget = null;
            this.dragTargetDropped = null;
        }
    }

    shakeCamera() {
        this.cameras.main.shake(300, 0.01);
    }

    runEndOfGame() {
        const { Inventory: { watchedTerminalGuides } } = store.getState();
        if (watchedTerminalGuides === 3 && this.gameWorld.runEndOfGame) {
            const bigroomScene = this.scene.get('bigroom');
            this.gameWorld.runEndOfGame = false;
            bigroomScene.gameWorld.runEndOfGame = false;
            this.cameras.main.fadeOut(1000, 201, 52, 126);
            this.cameras.main.once('camerafadeoutcomplete', () => {
                this.stopWalkingSound();
                ReactDOM.render(<Chat endChat />, document.getElementById('end-chat'));
                store.dispatch(setRunEndOfGame('dpRoom'));
                this.scene.switch('endOfGame');
            });
        }
    }
}

export default DPRoom;