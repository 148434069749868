/* eslint-disable */

import React, { Component } from "react";
import Dialogue from "./dialogue/dialogue";
import Container from "react-bootstrap/Container";
import classNames from "classnames";
import eventEmitter from '../utils/event-emitter';
import store from "../../store";

const DIALOGUE_SPECIAL_ACTIONS = { EXIT: "Exit", IMAGE: "Image" };

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      background: null,
      nextId: 0,
      msgArray: [],
      responseMessages: [],
      chat: {},
      chats: [],
      isBotTyping: false,
      isTheEnd: false,
    };
    this.minimumMessageDelay = 0;
    this.interval = null;
  }

  componentDidMount() {
    const { language: { choosenLanguage } } = store.getState();
    let chat;

    if (!this.props.startChat && !this.props.endChat) {
      if (choosenLanguage === 'en') {
        chat = require(`./resources/chatEn`);
      }
      else if (choosenLanguage === 'de') {
        chat = require(`./resources/chatDe`);
      }
    }
    if (this.props.startChat) {
      if (choosenLanguage === 'en') {
        chat = require(`./resources/startEn`);
      }
      else if (choosenLanguage === 'de') {
        chat = require(`./resources/startDe`);
      }
    }
    if (this.props.endChat) {
      if (choosenLanguage === 'en') {
        chat = require(`./resources/endEn`);
      }
      else if (choosenLanguage === 'de') {
        chat = require(`./resources/endDe`);
      }
    }

    const chats = this.getChats(chat);
    this.setState({ chat: chats });
    this.nextMessage();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentWillUnmount() {
    const { characteristics } = this.props;
    localStorage.setItem("characteristics", JSON.stringify(characteristics));
  }


  scrollToBottom = () => {
    this.msgBox.scroll({ top: this.msgBox.scrollHeight - this.msgBox.clientHeight, left: 0, behavior: 'smooth' });
  };

  getChats = (chatID) => {
    Dialogue.parse("Chats", chatID.default);
    const chat = Dialogue.dialogues;
    return chat;
  };

  setDelayForEachMessage = () => {
    const currentMessage = Dialogue.__getDialogue("Chats", this.state.nextId);
    this.minimumMessageDelay = Math.floor(
      (currentMessage.text.length * 100) / 1.75
    );
    this.minimumMessageDelay =
      this.minimumMessageDelay > 1000 ? this.minimumMessageDelay : 1000;
  };

  nextMessage = () => {
    let currentMessage = Dialogue.__getDialogue("Chats", this.state.nextId);
    if (this.handleSpecialAction(currentMessage)) {
      if (this.props.startChat) {
        eventEmitter.emit("CLOSE_START_CHAT");
      }
      if (this.props.endChat) {
        eventEmitter.emit("CLOSE_END_CHAT");
      }
      if (!this.props.startChat && !this.props.endChat) {
        eventEmitter.emit("CLOSE_INTERACTIVE_CHAT");
      }
      this.setState({ isBotTyping: false, isTheEnd: true });
      return;
    }
    this.setState({ isBotTyping: true });
    this.setDelayForEachMessage();
    this.interval = setInterval(() => {
      if (currentMessage && currentMessage.next) {
        this.handleSpecialAction(currentMessage);
        this.setState({
          nextId: currentMessage.next,
          msgArray: [...this.state.msgArray, currentMessage],
        });
        clearInterval(this.interval);
        this.nextMessage();
      } else {
        this.setState({
          msgArray: [...this.state.msgArray, currentMessage],
          responseMessages: currentMessage.responses,
        });
        this.setState({ isBotTyping: false });
        clearInterval(this.interval);
        return;
      }
    }, this.minimumMessageDelay);
  };

  sendMessage = (item) => {
    const { changeCharacteristics } = this.props;
    const currentMessage = Dialogue.__getDialogue("Chats", item);
    if (currentMessage.action) {
      changeCharacteristics(currentMessage.action);
    }
    currentMessage.user = true;
    this.setState(
      {
        responseMessages: [],
        msgArray: [...this.state.msgArray, currentMessage],
        nextId: currentMessage.next,
      },
      () => this.nextMessage()
    );
  };

  handleSpecialAction(currentMessage) {
    if (currentMessage.text.indexOf("||") === 0) {
      const string = currentMessage.text.substr(2);

      switch (string) {
        case DIALOGUE_SPECIAL_ACTIONS.EXIT:
          return true;
        default:
          return false;
      }
    }
    return false;
  }

  render() {
    const { responseMessages, isTheEnd } = this.state;

    return (
      <Container>
        <div className="interactive-chat chatsWrapper">
          <div
            className="messagesWrapper"
            ref={(el) => {
              this.msgBox = el;
            }}
          >
            {this.state.msgArray.map((item, index) => (
              <div
                key={index}
                className={classNames({
                  userMessageBlocks: item.user,
                  messageBlocks: !item.user,
                })}
              >
                {item.image ? (
                  <p>
                    <img
                      src={item.image}
                      alt="img"
                    />
                  </p>
                ) : (
                    <p>{item.text}</p>
                  )}
              </div>
            ))}
            {this.state.isBotTyping && (
              <div className="loader-wrapper">
                <div className="loader">Loading...</div>
              </div>
            )}
            <div
              style={{ float: "left", clear: "both", paddingBottom: "20px" }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            />
          </div>
          <div className="inputWrapper">
            {!isTheEnd &&
              responseMessages.map((item, index) => (
                <button
                  key={index}
                  className="button"
                  onClick={() => this.sendMessage(item)}
                >
                  {Dialogue.__getDialogue("Chats", item).text}
                </button>
              ))}
          </div>
        </div>
      </Container>
    );
  }
}

export default Chat;