import React from 'react';
import ReactDOM from 'react-dom';
import store from '../../store';
import { changeLanguage } from '../../store/actions';
import Chat from '../interactive-chat/Chat';

export default function LanguageSelect({ startGameHandler }) {
    const chooseLanguage = (_, type) => {
        store.dispatch(changeLanguage(type));
        startGameHandler(true);
        ReactDOM.render(<Chat startChat />, document.getElementById('start-chat'));
    }

    return (
        <div className="language-menu-container">
            <div className="language-menu">
                <h1>Choose language</h1>
                <button
                    onClick={(e) => chooseLanguage(e, 'en')}
                    type="button" className="english-button">English</button>
                <button
                    onClick={(e) => chooseLanguage(e, 'de')}
                    type="button" className="german-button">German</button>
            </div>
        </div>
    )
}
