import Phaser from 'phaser';

import store from '../../store';
import {
    bpPickObj, changeHintSound, changePhrase, disableCharacterMoving, inventoryAddCopyOfObject, inventoryPickObj,
    inventroySetCorrectPosition,
    setCorrectAll
} from '../../store/actions';
import { dpBgcolor, linesColor } from '../utils/colors';
import makeDraggableScene from '../utils/dragAndDrop';
import SpeechBubble from '../utils/speech-bubble';
import eventEmitter from '../utils/event-emitter';
import getRandomInt from '../utils/randomInt';

class BigRoom extends Phaser.Scene {
    constructor() {
        super({ key: "bigroomOnboarding" });

        this.gameWorld = {};
        this.gameWorld.avatar = null;
        this.gameWorld.vector = new Phaser.Math.Vector2();
        this.gameWorld.walkingAvailableArea = new Phaser.Geom.Polygon([410, 1184, 1147, 754, 2731, 1662, 1996, 2094]);
        this.gameWorld.arrow = new Phaser.Geom.Polygon([2222, 1423, 2265, 1449, 2274, 1417]);
        this.gameWorld.animalPolygon = new Phaser.Geom.Polygon([]);
        this.gameWorld.previousCoordinates = {};
        this.gameWorld.hideObjectX = -1000;
        this.gameWorld.polygonsForEmptyObjs = [];
        this.gameWorld.polygonsForHints = [];
        this.gameWorld.avatarSpeechBubble = new SpeechBubble(this);
        this.gameWorld.animalSpeechBubble = new SpeechBubble(this);
        this.gameWorld.animalNonActivitySpeechBubble = new SpeechBubble(this);
        this.gameWorld.notTaken = true;
        this.gameWorld.checkForNonactivity = true;
        this.gameWorld.bgMusicPlaying = false;
        this.gameWorld.walkMusicPlaying = false;
        this.gameWorld.onBoardingChatEnd = false;
        this.gameWorld.onBoardingChatStart = false;
        this.gameWorld.handAnimCount = 1;
    }

    create() {
        this.launchInventoryScene();
        this.createBackCameraAvatar();
        this.createObjects();
        this.createEmptyObjects();
        this.createColumn();
        this.detectPointerDown();
        this.createHints();
        this.createOverlap();
        makeDraggableScene(this, 'inventoryUI');
        this.createDragedObjects();
        this.detectFadeOut();
        this.createBgSound();
        this.createHintPapers();
    }

    handMoveAnim() {
        this.gameWorld.handMoveAnim = this.add.sprite(2630, 2180, "handAnim").setOrigin(0.5, 0.5).setDepth(5);
        const inventoryScene = this.scene.get('inventoryUI');
        const inventory = inventoryScene.gameState.inventoryUI;
        const coors = this.cameras.main.getWorldPoint(inventory.x, inventory.y);

        this.anims.create({
            key: "drag",
            frameRate: 15,
            frames: [{ key: 'handAnim', frame: 23 }],
            repeat: -1
        });
        this.gameWorld.handMoveAnim.play('drag');
        const tween = this.tweens.add({
            targets: this.gameWorld.handMoveAnim,
            x: { from: coors.x + 150, to: 2275 },
            y: { from: coors.y, to: 1510, },
            ease: 'Cubic.easeOut',
            duration: 1000,
            yoyo: false
        });

        tween.on('complete', function () {
            this.gameWorld.handMoveAnim.destroy();
            this.gameWorld.handMoveAnim = null;
            this.handDropAnim();
        }, this);
    }

    handDropAnim() {
        this.gameWorld.handDropAnim = this.add.sprite(2275, 1460, "dropHandAnim").setOrigin(0.5, 0.5).setDepth(5);
        this.anims.create({
            key: "drop",
            frameRate: 9,
            frames: this.anims.generateFrameNumbers("dropHandAnim", { start: 0, end: 8 }),
            repeat: 0
        });
        this.gameWorld.handDropAnim.play('drop');

        this.gameWorld.handDropAnim.on('animationcomplete', () => {
            this.gameWorld.handDropAnim.destroy();
            this.gameWorld.handDropAnim = null;
            if (this.gameWorld.handAnimCount !== 3) {
                this.gameWorld.handAnimCount += 1;
                const inventoryScene = this.scene.get('inventoryUI');
                inventoryScene.startHandAnim();
            }
        }, this.gameWorld.handDropAnim);
    }

    update() {
        this.checkAvatarCoordinates();
        this.checkCorrectPossition();
        this.checkAvatarAndColumnsY();
        this.rotateHintPapers();
    }

    changeBgMusicVolume() {
        const { Inventory: { videoPlaying } } = store.getState();
        if (videoPlaying && this.gameWorld.bgMusic) {
            this.gameWorld.bgMusic.setVolume(0.03);
        }
        if (!videoPlaying && this.gameWorld.bgMusic) {
            this.gameWorld.bgMusic.setVolume(0.3);
        }
    }

    launchInventoryScene() {
        this.scene.launch('inventoryUI');
    }

    detectFadeOut() {
        this.cameras.main.on('camerafadeoutcomplete', function (camera) {
            camera.fadeIn(500, 201, 52, 126);
        });
    }

    createBgSound() {
        if (this.gameWorld.bgMusicPlaying === false) {
            this.gameWorld.bgMusic = this.sound.add('bgSoundOP', { volume: 0.3, loop: true });
            this.gameWorld.bgMusic.play();
            this.gameWorld.bgMusicPlaying = true;
        }
    }

    playSound(soundName) {
        const { Inventory: { videoPlaying } } = store.getState();
        const volume = videoPlaying ? 0.01 : 1;
        if (!this.gameWorld[`${soundName}Music`]) {
            if (soundName === 'walk') {
                this.gameWorld[`${soundName}Music`] = this.sound.add(`${soundName}Sound`, { volume, loop: true });
                this.gameWorld.walkMusicPlaying = true;
            }
            else {
                this.gameWorld[`${soundName}Music`] = this.sound.add(`${soundName}Sound`, { volume });
            }
            this.gameWorld[`${soundName}Music`].play();
        }
        else {
            this.gameWorld[`${soundName}Music`].play({ volume });
        }
    }

    playButtonPressedSound() {
        if (!this.gameWorld.buttonPressedSound) {
            this.gameWorld.buttonPressedSound = this.sound.add('btnClickedSound', { volume: 1 });
            this.gameWorld.buttonPressedSound.play();
        }
        else {
            this.gameWorld.buttonPressedSound.play();
        }
    }

    playHintSound(soundName) {
        const { hintsSound: { currentHintSound }, Inventory: { videoPlaying } } = store.getState();
        const volume = videoPlaying ? 0.01 : 1;
        this.gameWorld[`${soundName}${currentHintSound}Music`] = this.sound.add(`${soundName}Sound${currentHintSound}`);
        this.gameWorld[`${soundName}${currentHintSound}Music`].play({ volume });
        store.dispatch(changeHintSound());
    }

    stopWalkingSound() {
        if (this.gameWorld.walkMusic) {
            this.gameWorld.walkMusic.stop();
            this.gameWorld.walkMusicPlaying = false;
            this.gameWorld.walkMusic = null;
        }
    }

    createHintPapers() {
        this.gameWorld.animalHintPaper = this.physics.add.image(2110, 1500, 'animalHintPaper').setOrigin(0.5, 0.5).setDepth(4);
        this.gameWorld.animalHintPaper.alphaValue = getRandomInt(5, 10);
    }

    rotateHintPapers() {
        if (this.gameWorld.animalHintPaper) {
            this.gameWorld.animalHintPaper.angle += this.gameWorld.animalHintPaper.alphaValue;
        }
    }

    createBackCameraAvatar() {
        const { BProom: { avatar } } = store.getState();
        const { avatarSpeechBubble } = this.gameWorld;

        this.add.image(0, 0, 'background').setOrigin(0, 0);
        this.gameWorld.avatar = this.physics.add.image(avatar.onboardingX, avatar.onboardingY, avatar.name).setOrigin(0.5, 0.75);
        this.gameWorld.avatar.setDepth(2);
        this.gameWorld.avatar.setTexture(avatar.name, 0);


        this.cameras.main.startFollow(this.gameWorld.avatar);
        this.cameras.main.setBackgroundColor(dpBgcolor);
        this.cameras.main.setBounds(0, 0, 1920 * 2, 2400, true);


        store.dispatch(changePhrase(0));
        const { language: { choosenLanguage }, onBoarding: { currentAvatarPhrase } } = store.getState();
        if (choosenLanguage === 'en') {
            const { onBoarding: { avatarPhrasesEn, animalPhrasesEn } } = store.getState();
            this.gameWorld.avatarPhrases = avatarPhrasesEn;
            this.gameWorld.animalPhrases = animalPhrasesEn;
        }
        else if (choosenLanguage === 'de') {
            const { onBoarding: { avatarPhrasesDe, animalPhrasesDE } } = store.getState();
            this.gameWorld.avatarPhrases = avatarPhrasesDe;
            this.gameWorld.animalPhrases = animalPhrasesDE;
        }
        const { avatarPhrases } = this.gameWorld;

        store.dispatch(disableCharacterMoving(true));

        new Promise(resolve => {
            setTimeout(() => {
                avatarSpeechBubble.create(avatar.onboardingX + 50, avatar.onboardingY - 340, 340, 220, avatarPhrases[currentAvatarPhrase]);
                resolve();
                store.dispatch(changePhrase(1));
            }, 2500);
        }).then(() => {
            return new Promise(resolve => {
                setTimeout(() => {
                    avatarSpeechBubble.destroy();
                    resolve();
                }, 2500);
            })
        }).then(() => {
            setTimeout(() => {
                avatarSpeechBubble.create(avatar.onboardingX + 50, avatar.onboardingY - 360, 360, 240, avatarPhrases[1]);
                store.dispatch(disableCharacterMoving(false));
                store.dispatch(changePhrase(2));
            }, 100)
        })
    }

    createObjects() {
        const { BProom: { objects: { animal } } } = store.getState();
        const { animalSpeechBubble, animalPhrases } = this.gameWorld;

        if (!animal.taken) {
            this.gameWorld.animal = this.physics.add.staticImage(animal.onboardingX, animal.onboardingY, animal.name).setOrigin(0, 0).refreshBody();
            this.animateAppear(this.gameWorld.animal)
            this.gameWorld.animalPolygon = new Phaser.Geom.Polygon(animal.polygonForColission);
            setTimeout(() => {
                animalSpeechBubble.create(animal.onboardingX + 80, animal.onboardingY - 180, 320, 160, animalPhrases[0]);
            }, 500)
        }
    }

    createDragedObjects() {
        const { Inventory: { objects }, BProom: { hints } } = store.getState();
        const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

        objects.forEach(elem => {
            const { name, correctPosition } = elem;
            if (correctPosition) {
                const equal = hints.find(hint => hint.for === name);
                this.gameWorld[name] = this.physics.add.staticImage(equal.x, equal.y, `${name}Correct`).setOrigin(0, 0);
                this.gameWorld[`hint${capitalize(name)}`] && (this.gameWorld[`hint${capitalize(name)}`].destroy());
            }
        })
    }

    createEmptyObjects() {
        const { BProom: { emptyObjs } } = store.getState();
        emptyObjs.forEach(obj => {
            this.add.image(obj.x, obj.y, obj.name).setOrigin(0, 0).setDepth(2);
            this.add.image(obj.x, obj.shadowY, `${obj.name}Shadow`).setOrigin(0, 0).setDepth(1);

            obj.lines.forEach(line => {
                const graphic = this.add.graphics().setDepth(3);
                graphic.lineStyle(4, linesColor);
                graphic.moveTo(line.x1, line.y1);
                graphic.lineTo(line.x2, line.y2);
                graphic.strokePath();
            });
            this.gameWorld.polygonsForEmptyObjs.push(new Phaser.Geom.Polygon(obj.collision));

        });
    }

    createColumn() {
        const { BProom: { columns } } = store.getState();
        columns.forEach((column, index) => {
            this[`column${index}`] = this.physics.add.staticImage(column.x, column.y, column.name).setOrigin(0, 0).setDepth(3);
            this[`ellipse${index}`] = this.add.ellipse(column.collision.x, column.collision.y,
                column.collision.width, column.collision.height, 0x000000);
            this[`ellipse${index}`].alpha = 0;
            this.physics.add.existing(this[`ellipse${index}`], true);
            this.gameWorld.polygonsForEmptyObjs.push(new Phaser.Geom.Polygon(column.points));
        });
    }

    createHints() {
        const { BProom: { hints } } = store.getState();
        const hint = hints[0];
        const { avatar, previousCoordinates } = this.gameWorld;

        this.gameWorld[hint.name] = this.physics.add.staticImage(hint.x, hint.y, hint.name)
            .setOrigin(0, 0).setDepth(3).refreshBody();
        this.gameWorld[`${hint.name}Shadow`] = this.physics.add.staticImage(hint.x, hint.shadowY, `${hint.name}Shadow`)
            .setOrigin(0, 0).setDepth(1);

        this.gameWorld[`${hint.for}HintPolygon`] = this.add.polygon(0, 0, hint.hintPoints, 0xffffff).setOrigin(0, 0).setAlpha(0.1);
        this.gameWorld[`${hint.for}HintPolygon`].setInteractive(new Phaser.Geom.Polygon(hint.hintPoints), Phaser.Geom.Polygon.Contains)
            .on('pointerdown', (pointer) => {
                if (pointer.event.detail > 1) {
                    return;
                }
                if (this.gameWorld.onBoardingChatEnd) {
                    this.playHintSound('openHints');
                    this.stopWalkingSound();
                    avatar.body.reset(previousCoordinates.x, previousCoordinates.y);

                    store.dispatch(disableCharacterMoving(true));
                    this.cameras.main.fadeOut(500, 201, 52, 126);
                    this.cameras.main.once('camerafadeoutcomplete', () => {
                        this.registry.set('hintBg', hint.for);
                        store.dispatch(disableCharacterMoving(false));
                        this.scene.switch(`hintScene`);
                    });
                }
            })
            .on('pointerover', () => {
                if (this.gameWorld.onBoardingChatEnd) {
                    this.input.setDefaultCursor('pointer');
                }
            })
            .on('pointerout', () => {
                if (this.gameWorld.onBoardingChatEnd) {
                    this.input.setDefaultCursor('default')
                }
            });

        hint.lines.forEach(line => {
            const graphic = this.add.graphics().setDepth(5);
            graphic.lineStyle(4, linesColor);
            graphic.moveTo(line.x1, line.y1);
            graphic.lineTo(line.x2, line.y2);
            graphic.strokePath();
        });
    }

    detectPointerDown() {
        const {
            avatar,
            vector,
            walkingAvailableArea,
            polygonsForEmptyObjs,
            polygonsForHints,
            avatarSpeechBubble,
            animalSpeechBubble,
            animal: animalObj,
            previousCoordinates,
        } = this.gameWorld;

        this.input.on('pointerdown', (pointer) => {
            const { onBoarding: { currentAvatarPhrase, disbaleCharacterMoving },
                BProom: { objects: { animal } }
            } = store.getState();

            if (disbaleCharacterMoving) {
                return;
            }
            avatarSpeechBubble.destroy();

            const transformedPoint = this.cameras.main.getWorldPoint(pointer.x, pointer.y);
            if (Phaser.Geom.Polygon.Contains(walkingAvailableArea, transformedPoint.x, transformedPoint.y)
                && polygonsForEmptyObjs.every(polygon => !Phaser.Geom.Polygon.Contains(polygon, transformedPoint.x, transformedPoint.y))
                && polygonsForHints.every(polygon => !Phaser.Geom.Polygon.Contains(polygon, transformedPoint.x, transformedPoint.y))
            ) {
                const angle = Phaser.Math.Angle.BetweenPoints(avatar, transformedPoint) * 180 / Math.PI;

                if (angle < 115 && angle > 45) avatar.setTexture('avatarOp', 0);
                if (angle > 115 || angle < -115) avatar.setTexture('avatarOp', 1);
                if (angle > -45 && angle < 45) avatar.setTexture('avatarOp', 2);
                if (angle < -45 && angle > -115) avatar.setTexture('avatarOp', 3);

                vector.x = transformedPoint.x;
                vector.y = transformedPoint.y;

                this.physics.moveToObject(avatar, vector, 240);
                if (this.gameWorld.walkMusicPlaying === false) {
                    this.playSound('walk');
                    this.gameWorld.walkMusicPlaying = true;
                }
            }

            if (currentAvatarPhrase === 2
                && !this.gameWorld.onBoardingChatStart
                && Phaser.Geom.Polygon.Contains(new Phaser.Geom.Polygon(animal.polygonForObject), transformedPoint.x, transformedPoint.y)) {
                this.gameWorld.onBoardingChatStart = true;
                store.dispatch(disableCharacterMoving(true));
                this.stopWalkingSound();
                avatar.body.reset(previousCoordinates.x, previousCoordinates.y);
                animalSpeechBubble.destroy();

                setTimeout(() => {
                    this.showInteractiveChat(animalObj.x, animalObj.y);
                    store.dispatch(disableCharacterMoving(false));
                }, 1000)

                eventEmitter.on("CLOSE_INTERACTIVE_CHAT", () => {
                    this.interactiveChatDOM.removeEventListener('click', this.onInteractiveChatClick);
                    this.interactiveChatDOM = null;
                    setTimeout(() => {
                        const interactiveChat = document.getElementById("interactive-chat");
                        interactiveChat.style.display = 'none';
                        store.dispatch(changePhrase(3));
                        this.closeInteractiveChat();
                        this.gameWorld.onBoardingChatEnd = true;
                    }, 500);
                });
            }
        });
    }

    checkAvatarCoordinates() {

        const { avatar, vector, walkingAvailableArea, previousCoordinates,
            polygonsForEmptyObjs, polygonsForHints, checkForNonactivity, animalPolygon, animalPhrases,
            animalNonActivitySpeechBubble, animal: animalObj } = this.gameWorld;
        const distance = Phaser.Math.Distance.Between(avatar.x, avatar.y, vector.x, vector.y);

        if (!Phaser.Geom.Polygon.Contains(walkingAvailableArea, avatar.x, avatar.y)) {
            avatar.body.reset(previousCoordinates.x, previousCoordinates.y);
            this.stopWalkingSound();
        }
        else if (polygonsForEmptyObjs.some(polygon => Phaser.Geom.Polygon.Contains(polygon, avatar.x, avatar.y))
            || polygonsForHints.some(polygon => Phaser.Geom.Polygon.Contains(polygon, avatar.x, avatar.y))
            || Phaser.Geom.Polygon.Contains(animalPolygon, avatar.x, avatar.y)
        ) {
            avatar.body.reset(previousCoordinates.x, previousCoordinates.y);
            this.stopWalkingSound();
        }
        else {
            previousCoordinates.x = avatar.x;
            previousCoordinates.y = avatar.y;
        }

        if (avatar.body.speed > 0 && distance < 10) {
            avatar.body.reset(vector.x, vector.y);
            this.stopWalkingSound();
        }
        const { onBoarding: { currentAvatarPhrase } } = store.getState();

        if (currentAvatarPhrase === 3 && animalObj.taken && checkForNonactivity) {
            this.gameWorld.checkForNonactivity = false;
            setTimeout(() => {
                if (this.gameWorld.notTaken) {
                    animalNonActivitySpeechBubble.create(animalObj.x + 70, animalObj.y - 270, 380, 260, animalPhrases[1]);
                    setTimeout(() => {
                        animalNonActivitySpeechBubble.destroy();
                    }, 5000);
                }
            }, 10000);
        }
    }

    createOverlap() {
        const { animal, avatar } = this.gameWorld;
        const inventoryScene = this.scene.get('inventoryUI');
        const { animalSpeechBubble } = this.gameWorld;

        this.physics.add.overlap(avatar, animal, (_, item) => {
            const { onBoarding: { currentAvatarPhrase } } = store.getState();

            if (currentAvatarPhrase === 3) {
                this.playSound('success');
                item.taken = true;
                item.body.enable = false;
                store.dispatch(bpPickObj('animal', true));
                animalSpeechBubble.destroy();

                inventoryScene.objToAdd = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'animalInactiv').setVisible(false);
                inventoryScene.objToAdd.name = 'animal';
                inventoryScene.needToUpdate = true;

                const playButton = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'playbuttonInactiv').setVisible(false);
                playButton.forObject = 'animal';
                inventoryScene.playButtonToAdd = playButton;

                const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'animalInactiv').setOrigin(0, 0).setVisible(false);
                objectCopy.setInteractive({ useHandCursor: true });
                inventoryScene.objToAddCopy = objectCopy;
                objectCopy.name = 'animal';
                const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true };
                store.dispatch(inventoryPickObj(objectCopy.name, objectToDrag));

                inventoryScene.flyToInventory(objectCopy.name);
                setTimeout(() => {
                    inventoryScene.startHandAnim();
                }, 1000)
            }
        });
    }

    checkCorrectPossitionForItem(itemName, hintForObj, inventoryScene, worldX, worldY) {
        const { animal: animalObj, animalSpeechBubble, animalNonActivitySpeechBubble, animalPhrases } = this.gameWorld;

        if (this.dragTarget &&
            this.dragTarget === itemName) {
            // destroy animation if user start drag before it end
            if (inventoryScene.gameState.startHandAnim
                || this.gameWorld.handMoveAnim
                || this.gameWorld.handDropAnim
            ) {
                inventoryScene.gameState.startHandAnim && inventoryScene.gameState.startHandAnim.destroy();
                this.gameWorld.handMoveAnim && this.gameWorld.handMoveAnim.destroy();
                this.gameWorld.handDropAnim && this.gameWorld.handDropAnim.destroy();
            }
        }

        if (this.dragTarget &&
            this.dragTarget === itemName &&
            hintForObj.body &&
            Phaser.Geom.Rectangle.Contains(hintForObj.body, worldX, worldY)) {
            // say to user that item is over correct position
        }
        if (this.dragTarget === itemName &&
            this.dragTargetDropped &&
            hintForObj.body &&
            Phaser.Geom.Rectangle.Contains(hintForObj.body, this.dragTargetDropped.x, this.dragTargetDropped.y)) {
            // item is placed correctly
            this.playSound('success');
            inventoryScene.needToUpdate = true;
            inventoryScene.flickPlayButton(itemName);

            this.gameWorld[`${itemName}HintPaper`].destroy();
            this.gameWorld[`${itemName}HintPaper`] = null;

            this[`dragTargetDropped${itemName}`] = this.dragTargetDropped;
            this[`dragTargetDropped${itemName}`].setTexture(`${itemName}Correct`).setDepth(2);
            this[`dragTargetDropped${itemName}`].x = hintForObj.x;
            this[`dragTargetDropped${itemName}`].y = hintForObj.y;
            hintForObj.destroy();
            this.gameWorld[`${itemName}HintPolygon`].destroy();
            this.input.setDefaultCursor('default')

            store.dispatch(inventroySetCorrectPosition(itemName, true));
            store.dispatch(setCorrectAll());

            setTimeout(() => {
                const { language: { choosenLanguage } } = store.getState();
                if (choosenLanguage === 'de') {
                    animalSpeechBubble.create(animalObj.x + 95, animalObj.y - 455, 580, 470, animalPhrases[2]);
                } else {
                    animalSpeechBubble.create(animalObj.x + 80, animalObj.y - 440, 520, 440, animalPhrases[2]);
                }
                inventoryScene.speechBubbleToRemove = animalSpeechBubble;
            }, 10);

        }
        if (this.dragTarget === itemName &&
            this.dragTargetDropped &&
            hintForObj.body &&
            !Phaser.Geom.Rectangle.Contains(hintForObj.body, this.dragTargetDropped.x, this.dragTargetDropped.y)) {
            // item is not placed correctly
            this.playSound('fail');
            this.shakeCamera();
            inventoryScene.flybackToInventory(itemName);

            this.dragTargetDropped.destroy();
            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, `${itemName}Inactiv`).setOrigin(0, 0);
            objectCopy.setInteractive({ useHandCursor: true });
            objectCopy.name = itemName;
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true }
            store.dispatch(inventoryAddCopyOfObject(objectCopy.name, objectToDrag));

            this.dragTarget = null;
            this.dragTargetDropped = null;
        }

        if (this.dragTarget &&
            this.dragTarget === itemName) {
            // start drag
            this.gameWorld.notTaken = false;
            animalNonActivitySpeechBubble.destroy();
        }
    }

    checkCorrectPossition() {
        const inventoryScene = this.scene.get('inventoryUI');
        const { worldX, worldY } = this.input.activePointer;
        const { hintAnimal } = this.gameWorld;

        this.checkCorrectPossitionForItem('animal', hintAnimal, inventoryScene, worldX, worldY);
    }

    checkAvatarAndColumnsY() {

        if (this.gameWorld.avatar.y > this.ellipse0.y) {
            this.column0.setDepth(1);
        } else {
            this.column0.setDepth(3);
        }

        if (this.gameWorld.avatar.y > this.ellipse1.y) {
            this.column1.setDepth(1);
        } else {
            this.column1.setDepth(3);
        }

        if (this.gameWorld.avatar.y > this.ellipse2.y) {
            this.column2.setDepth(1);
        } else {
            this.column2.setDepth(3);
        }
    }


    showInteractiveChat(x, y) {
        this.interactiveChatDOM = document.getElementById("interactive-chat");
        this.interactiveChatDOM.addEventListener('click', this.onInteractiveChatClick.bind(this))
        this.gameWorld.interactiveChat = this.add.dom(x + 35, y - 450, this.interactiveChatDOM, {
            'pointer-events': 'auto'
        }).setOrigin(0, 0);
        this.animateAppear(this.gameWorld.interactiveChat);
    }

    onInteractiveChatClick(event) {
        if (event.composedPath()[0].tagName === 'BUTTON') {
            this.playButtonPressedSound();
        }
    }

    closeInteractiveChat() {
        this.gameWorld.interactiveChat.destroy();
        this.gameWorld.interactiveChat = null;
    }

    shakeCamera() {
        this.cameras.main.shake(300, 0.01);
    }

    animateAppear(target) {
        this.tweens.add({
            targets: target,
            alpha: { from: 0, to: 1 },
            ease: 'Cubic.easeOut',
            duration: 1000,
            yoyo: false
        });
    }
}

export default BigRoom;