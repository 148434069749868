import store from "../../store";
import { inventorySetInProp, inventorySetLocationProp } from "../../store/actions";

export default function makeDraggableScene(droppedScene, inventorySceneKey) {
    const inventoryScene = droppedScene.scene.get(inventorySceneKey);
    // get obj
    inventoryScene.startDragging = function (pointer, targets) {
        inventoryScene.input.off("pointerdown", inventoryScene.startDragging, inventoryScene);
        // get currentTarget
        [inventoryScene.dragTarget] = targets;
        inventoryScene.move = false;
        inventoryScene.input.on("pointermove", inventoryScene.doDrag, inventoryScene);
        inventoryScene.input.on("pointerup", inventoryScene.stopDrag, inventoryScene);


        droppedScene.dragTarget = null;
        droppedScene.dragTargetDropped = null;

        if (typeof inventoryScene.dragTarget !== "undefined" && inventoryScene.dragTarget !== null &&
            inventoryScene.dragTarget.type !== 'playButton' && inventoryScene.dragTarget.type !== 'video'
        ) {
            store.dispatch(inventorySetInProp(inventoryScene.dragTarget.name, false));
        }

    }

    // moving obj
    inventoryScene.doDrag = function (pointer) {

        if (typeof inventoryScene.dragTarget !== "undefined" && inventoryScene.dragTarget !== null &&
            inventoryScene.dragTarget.type !== 'playButton' && inventoryScene.dragTarget.type !== 'video'
        ) {
            inventoryScene.dragTarget.x = pointer.x;
            inventoryScene.dragTarget.y = pointer.y;
            droppedScene.dragTarget = inventoryScene.dragTarget.name;
            inventoryScene.move = true;
        }

    }

    // drop obj
    inventoryScene.stopDrag = function (pointer) {
        inventoryScene.input.on("pointerdown", inventoryScene.startDragging, inventoryScene);
        inventoryScene.input.off("pointermove", inventoryScene.doDrag, inventoryScene);
        inventoryScene.input.off("pointerup", inventoryScene.stopDrag, inventoryScene);

        if (typeof inventoryScene.dragTarget !== "undefined" && inventoryScene.dragTarget !== null &&
            inventoryScene.dragTarget.type !== 'playButton' && inventoryScene.dragTarget.type !== 'video'
            && inventoryScene.move
        ) {
            // destroy item in inventoryScene
            inventoryScene.dragTarget.destroy();
            // get coordinates of pointer in droppedScene
            const droppedSceneCoordinates = droppedScene.input.activePointer;
            // transform coordinates to worldPoint
            const droppedSceneActualCoors = droppedScene.cameras.main.getWorldPoint(droppedSceneCoordinates.x, droppedSceneCoordinates.y);

            const { texture: key, name } = inventoryScene.dragTarget;
            inventoryScene.dragTarget = null;
            droppedScene.dragTargetDropped = droppedScene.add.image(droppedSceneActualCoors.x, droppedSceneActualCoors.y, key).setOrigin(0, 0);
            const action = { name, located: droppedScene, x: droppedSceneActualCoors.x, y: droppedSceneActualCoors.y };
            store.dispatch(inventorySetLocationProp(action));
        }
    }

    inventoryScene.input.on("pointerdown", inventoryScene.startDragging, inventoryScene);
}