export default `0 Oh dear, oh dear, oh dear.... [2,3]
2 What's wrong? -> 4
3 Everything ok? -> 4
4 We exhibits were put here just like that. -> 5
5 But each one has a very clear place. -> 6
6 Can you please help me? [7,8]
7 Yes. -> 9
8 I can try if you want! -> 9
9 Great! -> 10
10 There are notes in the showcases with clues as to which exhibit belongs inside. -> 11
11 But first you have to collect the exhibits... -> 12
12 And please hurry up, the guests are already at the door... -> 13
13 Can you please collect me first? [14,15]
14 Sure! -> 16
15 Okay, let's get going. -> 16
16 Great. -> 17
17 You can do it! -> 18
18 🤓🤓🥰 -> 10001

10001 ||Exit
`