import { DP2_PICK_OBJ, DP2_SET_AVATAR_XY } from "../types";

const initState = {
  avatar: { x: 242, y: 1165, name: 'avatarDp' },
  objects: {
    bird: { x: 1080, y: 650, taken: false, name: 'bird' }
  }
};

export default (
  state = initState,
  action
) => {
  switch (action.type) {
    case DP2_PICK_OBJ:
      return {
        ...state, objects: {
          ...state.objects, [action.payload.name]:
            { ...state.objects[action.payload.name], taken: action.payload.taken }
        }
      };
    case DP2_SET_AVATAR_XY:
      return { ...state, avatar: { ...state.avatar, ...action.payload } };
    default:
      return state;
  }
};