import Phaser from 'phaser';
import React from 'react';
import ReactDOM from 'react-dom';
import store from '../../store';
import {
    bpPickObj, changeHintSound, changeObjectPhrase, disableCharacterMoving, inventoryAddCopyOfObject, inventoryPickObj,
    inventroySetCorrectPosition, setAvatarXY, setCorrectAll, setRunEndOfGame
} from '../../store/actions';
import Chat from '../interactive-chat/Chat';
import { dpBgcolor, linesColor, portalsColor } from '../utils/colors';
import makeDraggableScene from '../utils/dragAndDrop';
import getRandomInt from '../utils/randomInt';
import SpeechBubble from '../utils/speech-bubble';

class BigRoom extends Phaser.Scene {
    constructor() {
        super({ key: "bigroom" });

        this.gameWorld = {};
        this.gameWorld.avatar = null;
        this.gameWorld.vector = new Phaser.Math.Vector2();
        this.gameWorld.walkingAvailableArea = new Phaser.Geom.Polygon([410, 1184, 1147, 754, 2731, 1662, 1996, 2094]);
        this.gameWorld.previousCoordinates = {};
        this.gameWorld.hideObjectX = -1000;
        this.gameWorld.polygonsForEmptyObjs = [];
        this.gameWorld.polygonsForHints = [];
        this.gameWorld.doFade = true;
        this.gameWorld.walkMusicPlaying = false;
        this.gameWorld.terminalSpeechBubble = false;
        this.gameWorld.activateTerminals = true;
        this.gameWorld.avatarSpeechBubble = new SpeechBubble(this);
        this.gameWorld.runEndOfGame = true;
    }

    create() {
        this.createBackCameraAvatar();
        this.createObjects();
        this.createEmptyObjects();
        this.createColumn();
        this.detectPointerDown();
        this.createHints();
        this.createPortals();
        this.createInfoTerminals();
        this.createOverlap();
        makeDraggableScene(this, 'inventoryUI');
        this.createDragedObjects();
        this.detectFadeOut();
        this.createHintPapers();
    }

    update() {
        this.checkAvatarCoordinates();
        this.checkCorrectPossition();
        this.checkAvatarAndColumnsY();
        this.checkAvatarAndTerminalY();
        this.activateInfoTerminals();
        this.rotateHintPapers();
        this.runEndOfGame();
    }

    setBackgroundColor() {
        this.cameras.main.setBackgroundColor(dpBgcolor);
    }

    detectFadeOut() {
        this.cameras.main.on('camerafadeoutcomplete', function (camera) {
            camera.fadeIn(500, 201, 52, 126);
        });
        this.cameras.main.fadeIn(500, 201, 52, 126);
    }

    playSound(soundName) {
        const { Inventory: { videoPlaying } } = store.getState();
        const volume = videoPlaying ? 0.01 : 1;
        if (!this.gameWorld[`${soundName}Music`]) {
            if (soundName === 'walk') {
                this.gameWorld[`${soundName}Music`] = this.sound.add(`${soundName}Sound`, { volume, loop: true });
                this.gameWorld.walkMusicPlaying = true;
            }
            else {
                this.gameWorld[`${soundName}Music`] = this.sound.add(`${soundName}Sound`, { volume });
            }
            this.gameWorld[`${soundName}Music`].play();
        }
        else {
            this.gameWorld[`${soundName}Music`].play({ volume });
        }
    }

    playHintSound(soundName) {
        const { hintsSound: { currentHintSound }, Inventory: { videoPlaying } } = store.getState();
        const volume = videoPlaying ? 0.01 : 1;
        this.gameWorld[`${soundName}${currentHintSound}Music`] = this.sound.add(`${soundName}Sound${currentHintSound}`);
        this.gameWorld[`${soundName}${currentHintSound}Music`].play({ volume });
        store.dispatch(changeHintSound());
    }

    playApplauseSound() {
        if (!this.gameWorld.applauseSound) {
            const { Inventory: { videoPlaying } } = store.getState();
            const volume = videoPlaying ? 0.01 : 1;
            this.gameWorld.applauseSound = this.sound.add('applauseSound', { volume });
            this.gameWorld.applauseSound.play();
            this.gameWorld.applauseSound.once('complete', () => {
                const inventoryScene = this.scene.get('inventoryUI');
                inventoryScene.gameState.confettiRain.destroy();
                inventoryScene.gameState.confettiRain = null;
            });
        }
    }

    stopApplauseSound() {
        if (this.gameWorld.applauseSound) {
            this.gameWorld.applauseSound.stop();
            this.gameWorld.applauseSound.destroy();
            this.gameWorld.applauseSound = null;
        }
    }

    stopWalkingSound() {
        if (this.gameWorld.walkMusic) {
            this.gameWorld.walkMusic.stop();
            this.gameWorld.walkMusicPlaying = false;
            this.gameWorld.walkMusic = null;
        }
    }

    createHintPapers() {
        this.gameWorld.appendixHintPaper = this.physics.add.image(1105, 1035, 'appendixHintPaper').setOrigin(0.5, 0.5).setDepth(4);
        this.gameWorld.appendixHintPaper.alphaValue = getRandomInt(5, 10);

        this.gameWorld.birdHintPaper = this.physics.add.image(1773, 1681, 'birdHintPaper').setOrigin(0.5, 0.5).setDepth(4);
        this.gameWorld.birdHintPaper.alphaValue = getRandomInt(5, 10);

        this.gameWorld.gorillahandHintPaper = this.physics.add.image(1438, 1453, 'gorillahandHintPaper').setOrigin(0.5, 0.5).setDepth(4);
        this.gameWorld.gorillahandHintPaper.alphaValue = getRandomInt(5, 10);

        this.gameWorld.revolutionaryHintPaper = this.physics.add.image(1745, 1184, 'revolutionaryHintPaper').setOrigin(0.5, 0.5).setDepth(4);
        this.gameWorld.revolutionaryHintPaper.alphaValue = getRandomInt(5, 10);

        this.gameWorld.shirtHintPaper = this.physics.add.image(1215, 1247, 'shirtHintPaper').setOrigin(0.5, 0.5).setDepth(4);
        this.gameWorld.shirtHintPaper.alphaValue = getRandomInt(5, 10);

        this.gameWorld.paintHintPaper = this.physics.add.image(1339, 920, 'paintHintPaper').setOrigin(0.5, 0.5).setDepth(4);
        this.gameWorld.paintHintPaper.alphaValue = getRandomInt(5, 10);
    }

    rotateHintPapers() {
        if (this.gameWorld.appendixHintPaper) {
            this.gameWorld.appendixHintPaper.angle += this.gameWorld.appendixHintPaper.alphaValue;
        }
        if (this.gameWorld.birdHintPaper) {
            this.gameWorld.birdHintPaper.angle += this.gameWorld.birdHintPaper.alphaValue;
        }
        if (this.gameWorld.gorillahandHintPaper) {
            this.gameWorld.gorillahandHintPaper.angle += this.gameWorld.gorillahandHintPaper.alphaValue;
        }
        if (this.gameWorld.revolutionaryHintPaper) {
            this.gameWorld.revolutionaryHintPaper.angle += this.gameWorld.revolutionaryHintPaper.alphaValue;
        }
        if (this.gameWorld.shirtHintPaper) {
            this.gameWorld.shirtHintPaper.angle += this.gameWorld.shirtHintPaper.alphaValue;
        }
        if (this.gameWorld.paintHintPaper) {
            this.gameWorld.paintHintPaper.angle += this.gameWorld.paintHintPaper.alphaValue;
        }
    }

    createBackCameraAvatar() {
        const { BProom: { avatar } } = store.getState();

        this.add.image(0, 0, 'background').setOrigin(0, 0);
        this.gameWorld.avatar = this.physics.add.image(avatar.onboardingX, avatar.onboardingY, avatar.name).setOrigin(0.5, 0.75);
        this.gameWorld.avatar.setDepth(2);
        this.gameWorld.avatar.setTexture(avatar.name, 0);

        this.cameras.main.setBounds(0, 0, 1920 * 2, 2400, true);
        this.setBackgroundColor();
        this.cameras.main.startFollow(this.gameWorld.avatar);

        const { language: { choosenLanguage } } = store.getState();
        if (choosenLanguage === 'en') {
            const { coreGameplay: { coreGameplayObjectsPhrasesEn } } = store.getState();
            this.gameWorld.coreGameplayObjectsPhrases = coreGameplayObjectsPhrasesEn;
        }
        else if (choosenLanguage === 'de') {
            const { coreGameplay: { coreGameplayObjectsPhrasesDe } } = store.getState();
            this.gameWorld.coreGameplayObjectsPhrases = coreGameplayObjectsPhrasesDe;
        }
    }

    createObjects() {
        const { BProom: { objects: { appendix, animal, gorillahand, shirt } } } = store.getState();

        if (!appendix.taken)
            this.gameWorld.appendix = this.physics.add.staticImage(appendix.x, appendix.y, appendix.name).setOrigin(0, 0).refreshBody();
        if (!animal.taken)
            this.gameWorld.animal = this.physics.add.staticImage(animal.x, animal.y, animal.name).setOrigin(0, 0).refreshBody();
        if (!gorillahand.taken)
            this.gameWorld.gorillahand = this.physics.add.staticImage(gorillahand.x, gorillahand.y, gorillahand.name).setOrigin(0, 0).refreshBody();
        if (!shirt.taken)
            this.gameWorld.shirt = this.physics.add.staticImage(shirt.x, shirt.y, shirt.name).setOrigin(0, 0).refreshBody();
    }

    createInfoTerminals() {
        const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
        const { BProom: { infoTerminals }, language: { choosenLanguage } } = store.getState();

        infoTerminals.forEach((terminal, index) => {
            this.gameWorld[`${terminal.name}`] = this.physics.add.staticImage(terminal.x, terminal.y, `inactive${capitalize(terminal.name)}`);
            this.gameWorld[`${terminal.name}`].setActive(false).refreshBody();
            this.gameWorld[`${terminal.name}`].body.enable = false;
            if (index === 2) {
                this.gameWorld.polygonsForEmptyObjs.push(new Phaser.Geom.Polygon(terminal.collision));
            }

            this.gameWorld[`${terminal.name}`].setInteractive({ useHandCursor: true })
                .on('pointerdown', () => {
                    store.dispatch(disableCharacterMoving(true));
                    const terminalSpeechBubble = new SpeechBubble(this);
                    const { Inventory: { correctAll },
                        coreGameplay: { infoTerminalsPhrasesEn, infoTerminalsPhrasesDe }
                    } = store.getState();
                    if (!correctAll) {
                        const { x, y } = this.gameWorld[`${terminal.name}`];
                        let offsetX;
                        let offsetY;
                        switch (terminal.name) {
                            case 'terminal1':
                                offsetX = 51;
                                offsetY = -238;
                                break;
                            case 'terminal2':
                                offsetX = 50;
                                offsetY = -227;
                                break;
                            case 'terminal3':
                                offsetX = 60;
                                offsetY = -235;
                                break;
                            default:
                                break;
                        }
                        if (choosenLanguage === 'en' && !this.gameWorld.terminalSpeechBubble) {
                            this.gameWorld.terminalSpeechBubble = true;
                            terminalSpeechBubble.create(x + 7 + offsetX, y - 130 + offsetY, 370, 290, infoTerminalsPhrasesEn[terminal.index])
                            setTimeout(() => {
                                terminalSpeechBubble.destroy();
                                this.gameWorld.terminalSpeechBubble = false;
                            }, 4000);
                        }
                        else if (choosenLanguage === 'de' && !this.gameWorld.terminalSpeechBubble) {
                            this.gameWorld.terminalSpeechBubble = true;
                            terminalSpeechBubble.create(x + offsetX + 9, y - 150 + offsetY, 390, 310, infoTerminalsPhrasesDe[terminal.index])
                            setTimeout(() => {
                                terminalSpeechBubble.destroy();
                                this.gameWorld.terminalSpeechBubble = false;
                            }, 4000);
                        }
                    }
                    setTimeout(() => {
                        store.dispatch(disableCharacterMoving(false));
                    }, 500)
                })
        })

        this.gameWorld.terminal3Helper = this.add.ellipse(2045, 2040,
            60, 60, 0x000000);
        this.gameWorld.terminal3Helper.alpha = 0;
        this.physics.add.existing(this.gameWorld.terminal3Helper, true);
    }

    createDragedObjects() {
        const { Inventory: { objects }, BProom: { hints } } = store.getState();
        const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

        objects.forEach(elem => {
            const { name, correctPosition } = elem;
            if (correctPosition) {
                const equal = hints.find(hint => hint.for === name);
                if (name === 'paint') {
                    this.gameWorld[name] = this.physics.add.staticImage(equal.x - 20, equal.y - 15, `${name}Correct`).setOrigin(0, 0);
                }
                else if (name === 'bird') {
                    this.gameWorld[name] = this.physics.add.staticImage(equal.x + 10, equal.y + 5, `${name}Correct`).setOrigin(0, 0);
                }
                else {
                    this.gameWorld[name] = this.physics.add.staticImage(equal.x, equal.y, `${name}Correct`).setOrigin(0, 0).setDepth(3);
                }
                this.gameWorld[`hint${capitalize(name)}`] && (this.gameWorld[`hint${capitalize(name)}`].destroy());
            }
        })
    }

    createEmptyObjects() {
        const { BProom: { emptyObjs } } = store.getState();
        emptyObjs.forEach(obj => {
            this.add.image(obj.x, obj.y, obj.name).setOrigin(0, 0).setDepth(2);
            this.add.image(obj.x, obj.shadowY, `${obj.name}Shadow`).setOrigin(0, 0).setDepth(1);

            obj.lines.forEach(line => {
                const graphic = this.add.graphics().setDepth(4);
                graphic.lineStyle(4, linesColor);
                graphic.moveTo(line.x1, line.y1);
                graphic.lineTo(line.x2, line.y2);
                graphic.strokePath();
            });
            this.gameWorld.polygonsForEmptyObjs.push(new Phaser.Geom.Polygon(obj.collision));

        });
    }

    createColumn() {
        const { BProom: { columns } } = store.getState();
        columns.forEach((column, index) => {
            this[`column${index}`] = this.physics.add.staticImage(column.x, column.y, column.name).setOrigin(0, 0).setDepth(3);
            this[`ellipse${index}`] = this.add.ellipse(column.collision.x, column.collision.y,
                column.collision.width, column.collision.height, 0x000000);
            this[`ellipse${index}`].alpha = 0;
            this.physics.add.existing(this[`ellipse${index}`], true);
            this.gameWorld.polygonsForEmptyObjs.push(new Phaser.Geom.Polygon(column.points));
        });
    }

    createHints() {
        const { BProom: { hints } } = store.getState();
        hints.forEach(hint => {
            this.gameWorld[hint.name] = this.physics.add.staticImage(hint.x, hint.y, hint.name)
                .setOrigin(0, 0).setDepth(2).refreshBody();
            if (hint.name === 'hintPaint') {
                this.gameWorld[hint.name].alpha = 0;
                this.gameWorld[`${hint.name}Helper`] = this.physics.add.staticImage(hint.x - 65, hint.y - 30, hint.name)
                    .setOrigin(0, 0).setDepth(6).setScale(2).refreshBody().setVisible(false);
            }

            if (hint.shadowY) {
                this.gameWorld[`${hint.name}Shadow`] = this.physics.add.staticImage(hint.x, hint.shadowY, `${hint.name}Shadow`)
                    .setOrigin(0, 0).setDepth(1);
            }

            const { avatar, previousCoordinates } = this.gameWorld;
            this.gameWorld[`${hint.for}HintPaperPolygon`] = this.add.polygon(0, 0, hint.hintPoints, 0xffffff).setOrigin(0, 0).setAlpha(0.1);
            this.gameWorld[`${hint.for}HintPaperPolygon`].setInteractive(new Phaser.Geom.Polygon(hint.hintPoints), Phaser.Geom.Polygon.Contains)
                .on('pointerdown', (pointer) => {
                    if (pointer.event.detail > 1) {
                        return;
                    }
                    this.playHintSound('openHints');
                    this.stopWalkingSound();
                    avatar.body.reset(previousCoordinates.x, previousCoordinates.y);
                    store.dispatch(disableCharacterMoving(true));
                    this.cameras.main.fadeOut(500, 201, 52, 126);
                    this.cameras.main.once('camerafadeoutcomplete', () => {
                        this.registry.set('hintBg', hint.for);
                        store.dispatch(disableCharacterMoving(false));
                        this.scene.switch(`hintScene`);
                    });
                })
                .on('pointerover', () => {
                    this.input.setDefaultCursor('pointer')
                })
                .on('pointerout', () => {
                    this.input.setDefaultCursor('default')
                });

            hint.lines.forEach(line => {
                const graphic = this.add.graphics().setDepth(5);
                graphic.lineStyle(4, linesColor);
                graphic.moveTo(line.x1, line.y1);
                graphic.lineTo(line.x2, line.y2);
                graphic.strokePath();
            });
        });
        this.gameWorld.animalHintPaperPolygon.destroy();
        this.gameWorld.animalHintPaperPolygon = null;
    }

    detectPointerDown() {
        const {
            avatar,
            vector,
            walkingAvailableArea,
            polygonsForEmptyObjs,
        } = this.gameWorld;

        this.input.on('pointerdown', (pointer) => {

            const { onBoarding: { disbaleCharacterMoving } } = store.getState();
            this.gameWorld.avatarSpeechBubble.destroy();
            if (disbaleCharacterMoving) {
                return;
            }

            const transformedPoint = this.cameras.main.getWorldPoint(pointer.x, pointer.y);
            if (Phaser.Geom.Polygon.Contains(walkingAvailableArea, transformedPoint.x, transformedPoint.y)
                && polygonsForEmptyObjs.every(polygon => !Phaser.Geom.Polygon.Contains(polygon, transformedPoint.x, transformedPoint.y))
                && this.gameWorld.polygonsForHints.every(polygon => !Phaser.Geom.Polygon.Contains(polygon, transformedPoint.x, transformedPoint.y))
            ) {
                const angle = Phaser.Math.Angle.BetweenPoints(avatar, transformedPoint) * 180 / Math.PI;

                if (angle < 115 && angle > 45) avatar.setTexture('avatarOp', 0);
                if (angle > 115 || angle < -115) avatar.setTexture('avatarOp', 1);
                if (angle > -45 && angle < 45) avatar.setTexture('avatarOp', 2);
                if (angle < -45 && angle > -115) avatar.setTexture('avatarOp', 3);

                vector.x = transformedPoint.x;
                vector.y = transformedPoint.y;

                this.physics.moveToObject(avatar, vector, 240);
                if (this.gameWorld.walkMusicPlaying === false) {
                    this.playSound('walk');
                    this.gameWorld.walkMusicPlaying = true;
                }
            }
        });
    }

    checkAvatarCoordinates() {
        const { avatar, vector, walkingAvailableArea, previousCoordinates, portal1, portal2, portal3,
            polygonsForEmptyObjs } = this.gameWorld;
        const distance = Phaser.Math.Distance.Between(avatar.x, avatar.y, vector.x, vector.y);

        if (!Phaser.Geom.Polygon.Contains(walkingAvailableArea, avatar.x, avatar.y)) {
            avatar.body.reset(previousCoordinates.x, previousCoordinates.y);
            this.stopWalkingSound();
        }
        else if (polygonsForEmptyObjs.some(polygon => Phaser.Geom.Polygon.Contains(polygon, avatar.x, avatar.y))
            || this.gameWorld.polygonsForHints.some(polygon => Phaser.Geom.Polygon.Contains(polygon, avatar.x, avatar.y))
        ) {
            avatar.body.reset(previousCoordinates.x, previousCoordinates.y);
            this.stopWalkingSound();
        }
        else {
            previousCoordinates.x = avatar.x;
            previousCoordinates.y = avatar.y;
        }

        if (avatar.body.speed > 0 && distance < 10) {
            avatar.body.reset(vector.x, vector.y);
            this.stopWalkingSound();
        }

        if (this.gameWorld.doFade && Phaser.Geom.Polygon.Contains(portal1, avatar.x, avatar.y)) {
            this.gameWorld.doFade = false;
            this.cameras.main.fadeOut(500, 201, 52, 126);
            this.cameras.main.once('camerafadeoutcomplete', () => {
                this.stopWalkingSound();
                this.scene.switch('dproom');
                avatar.body.reset(1535, 1017);
                avatar.setTexture('avatarOp', 0);
                this.registry.set('dpBg', 'dp1');
                this.clearListeners();
                this.gameWorld.doFade = true;
            });

            store.dispatch(setAvatarXY({ x: 1535, y: 1017 }));
        } else if (this.gameWorld.doFade && Phaser.Geom.Polygon.Contains(portal2, avatar.x, avatar.y)) {
            this.gameWorld.doFade = false;
            this.cameras.main.fadeOut(500, 201, 52, 126);
            this.cameras.main.once('camerafadeoutcomplete', () => {
                this.stopWalkingSound();
                this.scene.switch('dproom');
                avatar.body.reset(1535, 1017);
                avatar.setTexture('avatarOp', 0);
                this.registry.set('dpBg', 'dp2');
                this.clearListeners();
                this.gameWorld.doFade = true;
            });

            store.dispatch(setAvatarXY({ x: 1535, y: 1017 }));
        } else if (this.gameWorld.doFade && Phaser.Geom.Polygon.Contains(portal3, avatar.x, avatar.y)) {
            this.gameWorld.doFade = false;
            this.cameras.main.fadeOut(500, 201, 52, 126);
            this.cameras.main.once('camerafadeoutcomplete', () => {
                this.stopWalkingSound();
                this.scene.switch('dproom');
                avatar.body.reset(1120, 873);
                avatar.setTexture('avatarOp', 0);
                this.registry.set('dpBg', 'dp3');
                this.clearListeners();
                this.gameWorld.doFade = true;
            });

            store.dispatch(setAvatarXY({ x: 1120, y: 873 }));
        }
    }

    createPortals() {
        const graphics = this.add.graphics();
        this.gameWorld.arrowIn1 = this.add.image(1418, 935, 'opArrowIn').setOrigin(0, 0);
        this.gameWorld.arrowIn2 = this.add.image(1645, 1060, 'opArrowIn').setOrigin(0, 0);
        this.gameWorld.arrowIn3 = this.add.image(1197, 803, 'opArrowIn').setOrigin(0, 0);
        this.tweens.add({
            targets: [this.gameWorld.arrowIn1, this.gameWorld.arrowIn2, this.gameWorld.arrowIn3],
            alpha: { from: 0.3, to: 1 },
            ease: 'Cubic.easeOut',
            duration: 300,
            repeat: -1,
            yoyo: true
        })

        this.gameWorld.portal1 = new Phaser.Geom.Polygon([
            1397, 948,
            1417, 936,
            1405, 931,
            1478, 927,
            1478, 973,
            1466, 964,
            1443, 980
        ]);

        this.gameWorld.portal2 = new Phaser.Geom.Polygon([
            1646, 1060,
            1696, 1058,
            1696, 1088,
            1683, 1083,
            1671, 1092,
            1644, 1075,
            1656, 1067
        ]);

        this.gameWorld.portal3 = new Phaser.Geom.Polygon([
            1192, 804,
            1248, 803,
            1250, 835,
            1238, 826,
            1221, 837,
            1192, 822,
            1207, 810
        ]);

        graphics.fillStyle(portalsColor, 0.0);
        graphics.fillPoints(this.gameWorld.portal1.points, true);
        graphics.fillPoints(this.gameWorld.portal2.points, true);
        graphics.fillPoints(this.gameWorld.portal3.points, true);
    }

    createOverlap() {
        const { appendix, animal, gorillahand, shirt, avatar } = this.gameWorld;
        const inventoryScene = this.scene.get('inventoryUI');

        this.physics.add.overlap(avatar, appendix, (_, item) => {
            this.playSound('success');
            item.setActive(false).setVisible(false);
            item.body.enable = false;
            store.dispatch(bpPickObj('appendix', true));

            inventoryScene.objToAdd = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'appendixInactiv').setVisible(false);
            inventoryScene.objToAdd.name = 'appendix';
            inventoryScene.needToUpdate = true;

            const playButton = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'playbuttonInactiv').setVisible(false);
            playButton.forObject = 'appendix';
            inventoryScene.playButtonToAdd = playButton;

            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'appendixInactiv').setOrigin(0, 0).setVisible(false);
            objectCopy.setInteractive({ useHandCursor: true });
            inventoryScene.objToAddCopy = objectCopy;
            objectCopy.name = 'appendix';
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true };
            store.dispatch(inventoryPickObj(objectCopy.name, objectToDrag));

            inventoryScene.flyToInventory(objectCopy.name);
        });

        this.physics.add.overlap(avatar, animal, (_, item) => {
            this.playSound('success');
            item.setActive(false).setVisible(false);
            item.body.enable = false;
            store.dispatch(bpPickObj('animal', true));

            inventoryScene.objToAdd = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'animalInactiv').setVisible(false);
            inventoryScene.objToAdd.name = 'animal';
            inventoryScene.needToUpdate = true;

            const playButton = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'playbuttonInactiv').setVisible(false);
            playButton.forObject = 'animal';
            inventoryScene.playButtonToAdd = playButton;

            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'animalInactiv').setOrigin(0, 0).setVisible(false);
            objectCopy.setInteractive({ useHandCursor: true });
            inventoryScene.objToAddCopy = objectCopy;
            objectCopy.name = 'animal';
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true };
            store.dispatch(inventoryPickObj(objectCopy.name, objectToDrag));

            inventoryScene.flyToInventory(objectCopy.name);
        });

        this.physics.add.overlap(avatar, gorillahand, (_, item) => {
            this.playSound('success');
            item.setActive(false).setVisible(false);
            item.body.enable = false;
            store.dispatch(bpPickObj('gorillahand', true));

            inventoryScene.objToAdd = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'gorillahandInactiv').setVisible(false);
            inventoryScene.objToAdd.name = 'gorillahand';
            inventoryScene.needToUpdate = true;

            const playButton = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'playbuttonInactiv').setVisible(false);
            inventoryScene.playButtonToAdd = playButton;
            playButton.forObject = 'gorillahand';

            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'gorillahandInactiv').setOrigin(0, 0).setVisible(false);
            objectCopy.setInteractive({ useHandCursor: true });
            inventoryScene.objToAddCopy = objectCopy;
            objectCopy.name = 'gorillahand';
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true };
            store.dispatch(inventoryPickObj(objectCopy.name, objectToDrag));

            inventoryScene.flyToInventory(objectCopy.name);
        });

        this.physics.add.overlap(avatar, shirt, (_, item) => {
            this.playSound('success');
            item.setActive(false).setVisible(false);
            item.body.enable = false;
            store.dispatch(bpPickObj('shirt', true));

            inventoryScene.objToAdd = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'shirtInactiv').setVisible(false);
            inventoryScene.objToAdd.name = 'shirt';
            inventoryScene.needToUpdate = true;

            const playButton = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'playbuttonInactiv').setVisible(false);
            inventoryScene.playButtonToAdd = playButton;
            playButton.forObject = 'shirt';

            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'shirtInactiv').setOrigin(0, 0).setVisible(false);
            objectCopy.setInteractive({ useHandCursor: true });
            inventoryScene.objToAddCopy = objectCopy;
            objectCopy.name = 'shirt';
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true };
            store.dispatch(inventoryPickObj(objectCopy.name, objectToDrag));

            inventoryScene.flyToInventory(objectCopy.name);
        });

        this.physics.add.overlap(avatar, this.gameWorld.terminal1, (_, item) => {
            this.playSound('success');
            item.setActive(false).setVisible(false);
            item.body.enable = false;
            store.dispatch(bpPickObj('terminal1', true));

            inventoryScene.objToAdd = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'terminal1Inactiv').setVisible(false);
            inventoryScene.objToAdd.name = 'terminal1';
            inventoryScene.needToUpdate = true;

            const playButton = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'playbuttonInactiv').setVisible(false);
            inventoryScene.playButtonToAdd = playButton;
            playButton.forObject = 'terminal1';

            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'terminal1Inactiv').setOrigin(0, 0).setVisible(false);
            inventoryScene.objToAddCopy = objectCopy;
            objectCopy.name = 'terminal1';
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true };
            store.dispatch(inventoryPickObj(objectCopy.name, objectToDrag));

            inventoryScene.flyToInventory(objectCopy.name);

            setTimeout(() => {
                store.dispatch(inventroySetCorrectPosition(objectCopy.name, true));
                inventoryScene.flickPlayButton(objectCopy.name);
                inventoryScene.needToUpdate = true;
            }, 500)
        });

        this.physics.add.overlap(avatar, this.gameWorld.terminal2, (_, item) => {
            this.playSound('success');
            item.setActive(false).setVisible(false);
            item.body.enable = false;
            store.dispatch(bpPickObj('terminal2', true));

            inventoryScene.objToAdd = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'terminal2Inactiv').setVisible(false);
            inventoryScene.objToAdd.name = 'terminal2';
            inventoryScene.needToUpdate = true;

            const playButton = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'playbuttonInactiv').setVisible(false);
            inventoryScene.playButtonToAdd = playButton;
            playButton.forObject = 'terminal2';

            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'terminal2Inactiv').setOrigin(0, 0).setVisible(false);
            inventoryScene.objToAddCopy = objectCopy;
            objectCopy.name = 'terminal2';
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true };
            store.dispatch(inventoryPickObj(objectCopy.name, objectToDrag));

            inventoryScene.flyToInventory(objectCopy.name);

            setTimeout(() => {
                store.dispatch(inventroySetCorrectPosition(objectCopy.name, true));
                inventoryScene.flickPlayButton(objectCopy.name);
                inventoryScene.needToUpdate = true;
            }, 500)
        });

        this.physics.add.overlap(avatar, this.gameWorld.terminal3, (_, item) => {
            this.playSound('success');
            item.setActive(false).setVisible(false);
            item.body.enable = false;
            store.dispatch(bpPickObj('terminal3', true));

            inventoryScene.objToAdd = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'terminal3Inactiv').setVisible(false);
            inventoryScene.objToAdd.name = 'terminal3';
            inventoryScene.needToUpdate = true;

            const playButton = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'playbuttonInactiv').setVisible(false);
            inventoryScene.playButtonToAdd = playButton;
            playButton.forObject = 'terminal3';

            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, 'terminal3Inactiv').setOrigin(0, 0).setVisible(false);
            inventoryScene.objToAddCopy = objectCopy;
            objectCopy.name = 'terminal3';
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true };
            store.dispatch(inventoryPickObj(objectCopy.name, objectToDrag));

            inventoryScene.flyToInventory(objectCopy.name);

            setTimeout(() => {
                store.dispatch(inventroySetCorrectPosition(objectCopy.name, true));
                inventoryScene.flickPlayButton(objectCopy.name);
                inventoryScene.needToUpdate = true;
            }, 500)
        });
    }

    checkCorrectPossitionForItem(itemName, hintForObj, inventoryScene, worldX, worldY) {
        const { coreGameplayObjectsPhrases } = this.gameWorld;
        const { coreGameplay: { coreGameplayCurrentObjectPhrase } } = store.getState();
        let originBody;
        if (itemName === 'paint' && this.gameWorld.hintPaintHelper) {
            originBody = hintForObj.body;
            hintForObj.body = this.gameWorld.hintPaintHelper.body;
            this.gameWorld.hintPaintHelper.destroy();
            this.gameWorld.hintPaintHelper = null;
        }

        if (this.dragTarget &&
            this.dragTarget === itemName &&
            hintForObj.body &&
            Phaser.Geom.Rectangle.Contains(hintForObj.body, worldX, worldY)) {
            // say to user that item is over correct position
        }
        if (this.dragTarget === itemName &&
            this.dragTargetDropped &&
            hintForObj.body &&
            Phaser.Geom.Rectangle.Contains(hintForObj.body, this.dragTargetDropped.x, this.dragTargetDropped.y)) {
            // item is placed correctly
            this.playSound('success');
            inventoryScene.needToUpdate = true;
            inventoryScene.flickPlayButton(itemName);

            this.gameWorld[`${itemName}HintPaper`].destroy();
            this.gameWorld[`${itemName}HintPaper`] = null;

            this[`dragTargetDropped${itemName}`] = this.dragTargetDropped;
            this[`dragTargetDropped${itemName}`].setTexture(`${itemName}Correct`).setDepth(2);
            if (itemName === 'paint') {
                hintForObj.body = originBody;
                const x = hintForObj.x - 20;
                const y = hintForObj.y - 15;
                this[`dragTargetDropped${itemName}`].x = x;
                this[`dragTargetDropped${itemName}`].y = y;
                this.add.image(x, y + 249, "paintShadow").setOrigin(0, 0).setDepth(1);
            }
            else if (itemName === 'bird') {
                const x = hintForObj.x + 10;
                const y = hintForObj.y + 5;
                this[`dragTargetDropped${itemName}`].x = x;
                this[`dragTargetDropped${itemName}`].y = y;
                this.add.image(x + 93, y + 248, "birdShadow").setOrigin(0, 0).setDepth(1);
            }
            else {
                this[`dragTargetDropped${itemName}`].x = hintForObj.x;
                this[`dragTargetDropped${itemName}`].y = hintForObj.y;
            }

            hintForObj.destroy();
            this.gameWorld[`${itemName}HintPaperPolygon`].destroy();
            this.input.setDefaultCursor('default');

            let xOffset;
            let yOffset;
            switch (itemName) {
                case 'gorillahand':
                    xOffset = 87;
                    yOffset = -80;
                    break;
                case 'animal':
                    xOffset = 80;
                    yOffset = -73;
                    break;
                case 'bird':
                    xOffset = 193;
                    yOffset = -40;
                    break;
                case 'paint':
                    xOffset = 105;
                    yOffset = -65;
                    break;
                case 'appendix':
                    xOffset = 98;
                    yOffset = -90;
                    break;
                case 'revolutionary':
                    xOffset = 93;
                    yOffset = -79;
                    break;
                case 'shirt':
                    xOffset = 130;
                    yOffset = -70;
                    break;
                default:
                    break;
            }

            this.gameWorld[`${itemName}SpeechBubble`] = new SpeechBubble(this);
            let y = 0;
            let height = 160;
            if (coreGameplayCurrentObjectPhrase === 0) {
                y = 120;
                height = 280;
            }
            setTimeout(() => {
                this.gameWorld[`${itemName}SpeechBubble`].create(this[`dragTargetDropped${itemName}`].x + xOffset + 5,
                    this[`dragTargetDropped${itemName}`].y - y + yOffset, 360, height,
                    coreGameplayObjectsPhrases[coreGameplayCurrentObjectPhrase]);
                store.dispatch(changeObjectPhrase(coreGameplayCurrentObjectPhrase + 1));
            }, 10);
            setTimeout(() => {
                this.gameWorld[`${itemName}SpeechBubble`].destroy();
            }, 4000);
            store.dispatch(inventroySetCorrectPosition(itemName, true));
            store.dispatch(setCorrectAll());
        }
        if (this.dragTarget === itemName &&
            this.dragTargetDropped &&
            hintForObj.body &&
            !Phaser.Geom.Rectangle.Contains(hintForObj.body, this.dragTargetDropped.x, this.dragTargetDropped.y)) {
            // item is not placed correctly
            this.playSound('fail');
            this.shakeCamera();
            inventoryScene.flybackToInventory(itemName);

            this.dragTargetDropped.destroy();
            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, `${itemName}Inactiv`).setOrigin(0, 0);
            objectCopy.setInteractive({ useHandCursor: true });
            objectCopy.name = itemName;
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true }
            store.dispatch(inventoryAddCopyOfObject(objectCopy.name, objectToDrag));

            this.dragTarget = null;
            this.dragTargetDropped = null;
        }
    }

    checkCorrectPossition() {
        const inventoryScene = this.scene.get('inventoryUI');
        const { worldX, worldY } = this.input.activePointer;
        const { hintBird, hintAnimal, hintAppendix, hintGorillahand, hintPaint, hintRevolutionary, hintShirt } = this.gameWorld;

        this.checkCorrectPossitionForItem('gorillahand', hintGorillahand, inventoryScene, worldX, worldY);
        this.checkCorrectPossitionForItem('bird', hintBird, inventoryScene, worldX, worldY);
        this.checkCorrectPossitionForItem('paint', hintPaint, inventoryScene, worldX, worldY);
        this.checkCorrectPossitionForItem('animal', hintAnimal, inventoryScene, worldX, worldY);
        this.checkCorrectPossitionForItem('revolutionary', hintRevolutionary, inventoryScene, worldX, worldY);
        this.checkCorrectPossitionForItem('appendix', hintAppendix, inventoryScene, worldX, worldY);
        this.checkCorrectPossitionForItem('shirt', hintShirt, inventoryScene, worldX, worldY);
    }

    checkAvatarAndColumnsY() {

        if (this.gameWorld.avatar.y > this.ellipse0.y) {
            this.column0.setDepth(1);
        } else {
            this.column0.setDepth(3);
        }

        if (this.gameWorld.avatar.y > this.ellipse1.y) {
            this.column1.setDepth(1);
        } else {
            this.column1.setDepth(3);
        }

        if (this.gameWorld.avatar.y > this.ellipse2.y) {
            this.column2.setDepth(1);
        } else {
            this.column2.setDepth(3);
        }
    }

    checkAvatarAndTerminalY() {
        if (this.gameWorld.avatar.y > this.gameWorld.terminal3Helper.y) {
            this.gameWorld.terminal3.setDepth(1);
        } else {
            this.gameWorld.terminal3.setDepth(3);
        }
    }

    activateInfoTerminals() {
        const inventoryScene = this.scene.get('inventoryUI');
        const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
        const { Inventory: { correctAll }, BProom: { infoTerminals },
            coreGameplay: { objectPlacedAvatarPhraseEn, objectPlacedAvatarPhraseDe },
            language: { choosenLanguage }
        } = store.getState();
        const { avatarSpeechBubble, avatar } = this.gameWorld;

        if (this.gameWorld.activateTerminals && correctAll) {
            inventoryScene.gameState.confettiRain.setVisible(true);
            inventoryScene.confettiAnim();
            this.playApplauseSound();
            this.gameWorld.polygonsForEmptyObjs.pop();
            infoTerminals.forEach((terminal, index) => {
                this.gameWorld[`${terminal.name}`].setTexture(`active${capitalize(terminal.name)}`);
                this.gameWorld[`${terminal.name}`].setActive(true);
                this.gameWorld[`${terminal.name}`].body.enable = true;
                this.gameWorld[`${terminal.name}`].off('pointerdown');

                if (index > 0) {
                    this.gameWorld[`${terminal.name}`].x = terminal.activeX;
                    this.gameWorld[`${terminal.name}`].y = terminal.activeY
                }
                this.tweens.add({
                    targets: this.gameWorld[`${terminal.name}`],
                    alpha: { from: 0.5, to: 1 },
                    ease: 'Cubic.easeOut',
                    duration: 100,
                    repeat: -1,
                    yoyo: true
                });
            })
            if (choosenLanguage === 'en') {
                setTimeout(() => {
                    avatarSpeechBubble.create
                        (avatar.x + 50, avatar.y - 280, 320, 160, objectPlacedAvatarPhraseEn[0]);
                    setTimeout(() => {
                        avatarSpeechBubble.destroy();
                    }, 3000);
                }, 1000)
            }
            else if (choosenLanguage === 'de') {
                setTimeout(() => {
                    avatarSpeechBubble.create
                        (avatar.x + 50, avatar.y - 360, 320, 240, objectPlacedAvatarPhraseDe[0]);
                    setTimeout(() => {
                        avatarSpeechBubble.destroy();
                    }, 3000);
                }, 1000);
            }
            this.gameWorld.activateTerminals = false;
        }
    }

    clearListeners() {
        this.dragTarget = null;
        this.dragTargetDropped = null;
        const inventoryScene = this.scene.get('inventoryUI');
        inventoryScene.dragTarget = null;
        inventoryScene.dragTargetDropped = null;
        inventoryScene.input.off("pointerdown", null, null);
        inventoryScene.input.off("pointermove", null, null);
        inventoryScene.input.off("pointerup", null, null);
    }

    shakeCamera() {
        this.cameras.main.shake(300, 0.01);
    }

    runEndOfGame() {
        const { Inventory: { watchedTerminalGuides } } = store.getState();
        if (watchedTerminalGuides === 3 && this.gameWorld.runEndOfGame) {
            const dpScene = this.scene.get('dproom');
            const onboardingScene = this.scene.get('bigroomOnboarding');
            this.stopApplauseSound();
            this.gameWorld.runEndOfGame = false;
            dpScene.gameWorld.runEndOfGame = false;
            this.cameras.main.fadeOut(1000, 201, 52, 126);
            this.cameras.main.once('camerafadeoutcomplete', () => {
                onboardingScene.gameWorld.bgMusic.pause();
                this.stopWalkingSound();
                ReactDOM.render(<Chat endChat />, document.getElementById('end-chat'));
                store.dispatch(setRunEndOfGame('bgRoom'));
                this.scene.switch('endOfGame');
            });
        }
    }
}

export default BigRoom;