import { createStore, combineReducers } from "redux";

import BProomReducer from './reducer/BProomReducer';
import CoreGamplayReducer from "./reducer/CoreGamplayReducer";
import DProom1Reducer from './reducer/DProom1Reducer';
import DProom2Reducer from './reducer/DProom2Reducer';
import DProom3Reducar from "./reducer/DProom3Reducar";
import hintSoundReducer from "./reducer/hintSoundReducer";
import InventoryReducer from "./reducer/InventoryReducer";
import languageReducer from "./reducer/languageReducer";
import OnboardingReducer from "./reducer/OnboardingReducer";

const reducer = combineReducers({
  BProom: BProomReducer,
  DP1room: DProom1Reducer,
  DP2room: DProom2Reducer,
  DP3room: DProom3Reducar,
  Inventory: InventoryReducer,
  onBoarding: OnboardingReducer,
  coreGameplay: CoreGamplayReducer,
  language: languageReducer,
  hintsSound: hintSoundReducer,
});

export default createStore(reducer);

