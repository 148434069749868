import Phaser from 'phaser';

import store from '../../store';
import { inventoryAddCopyOfObject } from '../../store/actions';
import { hintBgColor } from '../utils/colors';
import makeDraggableScene from '../utils/dragAndDrop';

class hintScene extends Phaser.Scene {
    constructor() {
        super({ key: "hintScene" });

        this.gameWorld = {};
        this.gameWorld.bg = null;
        this.gameWorld.hideObjectX = -1000;
        this.gameWorld.clickedOnBg = false;
    }

    preload() {
        this.preloadBackground();
        this.setBackgroundColor();
        this.setFadeIn();
    }

    create() {
        this.createBackCamera();
        this.detectPointerDown();
        this.changeBg();

        makeDraggableScene(this, 'inventoryUI');
    }

    update() {
        this.returnItemToInventoryUI();
    }

    preloadBackground() {
        const { language: { choosenLanguage } } = store.getState();
        let hintLanguage = '';
        if (choosenLanguage === 'de') {
            hintLanguage = '/germanHints';
        }
        this.load.image('animalHint', `assets/detailviewsHints${hintLanguage}/animalHint.png`);
        this.load.image('birdHint', `assets/detailviewsHints${hintLanguage}/birdHint.png`);
        this.load.image('shirtHint', `assets/detailviewsHints${hintLanguage}/shirtHint.png`);
        this.load.image('revolutionaryHint', `assets/detailviewsHints${hintLanguage}/revolutionaryHint.png`);
        this.load.image('paintHint', `assets/detailviewsHints${hintLanguage}/paintHint.png`);
        this.load.image('appendixHint', `assets/detailviewsHints${hintLanguage}/appendixHint.png`);
        this.load.image('gorillahandHint', `assets/detailviewsHints${hintLanguage}/gorillahandHint.png`);
    }

    setBackgroundColor() {
        this.cameras.main.setBackgroundColor(hintBgColor);
    }

    setFadeIn() {
        this.cameras.main.fadeIn(500, 201, 52, 126);
    }

    createBackCamera() {
        this.cameras.main.setBounds(0, 0, 1920, 1200, true);

        this.gameWorld.bg = this.registry.get('hintBg');
        this.gameWorld.background = this.add.image(0, 0, `${this.gameWorld.bg}Hint`).setOrigin(0, 0).setInteractive({ useHandCursor: true });
    }

    detectPointerDown() {
        this.gameWorld.background.on('pointerdown', (pointer) => {
            if (pointer.event.detail > 1) {
                return;
            }
            if (this.gameWorld.clickedOnBg) {
                return;
            }
            this.gameWorld.clickedOnBg = true;
            this.cameras.main.fadeOut(500, 201, 52, 126);
            this.cameras.main.once('camerafadeoutcomplete', () => {
                this.clearListeners();
                if (this.gameWorld.bg !== 'animal') {
                    this.scene.switch('bigroom');
                    this.gameWorld.clickedOnBg = false;
                    makeDraggableScene(this.scene.get('bigroom'), 'inventoryUI');
                } else {
                    this.scene.switch('bigroomOnboarding');
                    this.gameWorld.clickedOnBg = false;
                    makeDraggableScene(this.scene.get('bigroomOnboarding'), 'inventoryUI');
                }
            });
        })
    }

    changeBg() {
        this.events.on(Phaser.Scenes.Events.WAKE, function () {
            makeDraggableScene(this, 'inventoryUI');
            this.setFadeIn();
            this.gameWorld.bg = this.registry.get('hintBg');
            this.gameWorld.background.setTexture(`${this.gameWorld.bg}Hint`);
        }, this);
    }

    returnItemToInventoryUI() {
        if (this.dragTargetDropped) {
            const inventoryScene = this.scene.get('inventoryUI');
            inventoryScene.flybackToInventory(this.dragTarget);

            this.dragTargetDropped.destroy();
            const objectCopy = inventoryScene.add.image(this.gameWorld.hideObjectX, 0, `${this.dragTarget}Inactiv`).setOrigin(0, 0);
            objectCopy.setInteractive({ useHandCursor: true });
            objectCopy.name = this.dragTarget;
            const objectToDrag = { object: objectCopy, located: inventoryScene, inInventory: true }
            store.dispatch(inventoryAddCopyOfObject(objectCopy.name, objectToDrag));
            inventoryScene.needToUpdate = true;

            this.dragTarget = null;
            this.dragTargetDropped = null;
        }
    }

    clearListeners() {
        this.dragTarget = null;
        this.dragTargetDropped = null;
        const inventoryScene = this.scene.get('inventoryUI');
        inventoryScene.dragTarget = null;
        inventoryScene.dragTargetDropped = null;
        inventoryScene.input.off("pointerdown", null, null);
        inventoryScene.input.off("pointermove", null, null);
        inventoryScene.input.off("pointerup", null, null);
    }
}

export default hintScene;