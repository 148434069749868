import { CHANGE_CORE_GAMEPLAY_PHRASE, SET_RUN_END_OF_GAME } from "../types";

const initState = {
    coreGameplayCurrentObjectPhrase: 0,
    coreGameplayObjectsPhrasesEn: [
        'Oh yeah! You found the correct place! Look. There is now a play button on the object',
        'And another one! You are awesome!',
        'That was tricky!',
        'You are a real PRO!!',
        'You did it. Yeahhhh!',
        'Brilliant!',
    ],
    coreGameplayObjectsPhrasesDe: [
        'Superduper! Schau, der Playbutton im Inventar ist aktiviert!',
        'Und noch eins! Großartigst!',
        'Das war nicht ganz einfach...',
        'Du bist ein echter PRO!',
        'Hast Du das vorher geübt?',
        'Famos!',
    ],
    infoTerminalsPhrasesEn: [
        'Here you can go on a journey through time. But before that, you have to get things to the right place.',
        'Here you can learn about the sound archive. But before that, you have to get things to the right place.',
        'Here you can take a little tour. But before that, things have to be put in the right place.',
    ],
    infoTerminalsPhrasesDe: [
        'Hier kannst Du eine Zeitreise machen. Aber zuvor müssen die Dinge an den richtigen Ort gebracht werden.',
        'Hier kannst Du etwas über das Lautarchiv erfahren. Aber zuvor müssen die Dinge an den richtigen Ort gebracht werden.',
        'Hier kannst Du eine kleine Führung machen. Aber zuvor müssen die Dinge an den richtigen Ort gebracht werden.',
    ],
    objectPlacedAvatarPhraseEn: [
        'Look, now the info terminals are unlocked!'
    ],
    objectPlacedAvatarPhraseDe: [
        'Ohh, schau mal. Die Infoterminals sind jetzt freigeschaltet!'
    ],
    runEndOfGamFrom: null,
};

export default (
    state = initState,
    action
) => {
    switch (action.type) {
        case CHANGE_CORE_GAMEPLAY_PHRASE:
            return { ...state, coreGameplayCurrentObjectPhrase: action.payload };
        case SET_RUN_END_OF_GAME:
            return { ...state, runEndOfGamFrom: action.payload };
        default:
            return state;
    }
};