export const BP_PICK_OBJ = "BP_PICK_OBJ";
export const SET_AVATAR_XY = 'SET_AVATAR_XY';
export const DP1_PICK_OBJ = "DP1_PICK_OBJ";
export const DP1_SET_AVATAR_XY = 'DP1_SET_AVATAR_XY';
export const DP2_PICK_OBJ = "DP2_PICK_OBJ";
export const DP2_SET_AVATAR_XY = 'DP2_SET_AVATAR_XY';
export const DP3_PICK_OBJ = "DP3_PICK_OBJ";
export const DP3_SET_AVATAR_XY = 'DP3_SET_AVATAR_XY';
export const INVENTORY_PICK_OBJ = 'INVENTORY_PICK_OBJ';
export const INVENTORY_SET_IN_INVENTORY_PROP = 'INVENTORY_SET_IN_INVENTORY_PROP';
export const INVENTORY_SET_LOCATION_PROP = 'INVENTORY_SET_LOCATION_PROP';
export const INVENTORY_SET_CORRECT_POSITION = 'INVENTORY_SET_CORRECT_POSITION';
export const INVENTORY_ADD_COPY_OF_OBJECT = 'INVENTORY_ADD_COPY_OF_OBJECT';
export const CHANGE_CORE_GAMEPLAY_PHRASE = "CHANGE_CORE_GAMEPLAY_PHRASE";
export const CHANGE_PHRASE = "CHANGE_PHRASE";
export const DISABLE_CHARACTER_MOVING = "DISABLE_CHARACTER_MOVING";
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_HINT_SOUND = 'CHANGE_HINT_SOUND';
export const SET_CORRECT_ALL = 'SET_CORRECT_ALL';
export const WATCHED_TERMINAL_GUIDES = 'WATCHED_TERMINAL_GUIDES';
export const SET_RUN_END_OF_GAME = 'SET_RUN_END_OF_GAME';
export const SET_VIDEO_PLAYING = 'SET_VIDEO_PLAYING';