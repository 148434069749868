export const linesColor = 0xb51b62;
export const portalsColor = 0x3396c9;
export const dpBgcolor = "#c9347e";
export const hintBgColor = "#db408d";
export const speechBubbleLineColor = 0x15204e;
export const speechBubbleTextColor = '#0f103b';
export const speechBubbleColor = 0xffffff;
export const speechBubbleTextBoxColor = 0x78b7c3;
export const speechBubbleTextBoxLineColor = 0xe4e4e3;
export const progressBoxColor = 0x15204e;
export const progressBarColor = 0x15204e;
export const progressTextColor = '#ffffff';
export const inventroyVideoBgColor = 0x15204e;