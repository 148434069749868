import { DP3_PICK_OBJ, DP3_SET_AVATAR_XY } from "../types";

const initState = {
    avatar: { x: 200, y: 850, name: 'avatarDp' },
    objects: {
        revolutionary: { x: 558, y: 452, taken: false, name: 'revolutionary' }
    }
};

export default (
    state = initState,
    action
) => {
    switch (action.type) {
        case DP3_PICK_OBJ:
            return {
                ...state, objects: {
                    ...state.objects, [action.payload.name]: {
                        ...state.objects[action.payload.name], taken: action.payload.taken
                    }
                }
            };
        case DP3_SET_AVATAR_XY:
            return { ...state, avatar: { ...state.avatar, ...action.payload } };
        default:
            return state;
    }
};