import { CHANGE_HINT_SOUND } from "../types";

const initState = {
    currentHintSound: 1,
};

export default (
    state = initState,
    action
) => {
    switch (action.type) {
        case CHANGE_HINT_SOUND:
            {
                if (state.currentHintSound === 6) {
                    return {
                        ...state, currentHintSound: 1
                    }
                }
                return {
                    ...state, currentHintSound: state.currentHintSound + 1
                }
            }

        default:
            return state;
    }
};