import React from 'react';
import ReactDOM from 'react-dom';
import Phaser from 'phaser';

import Chat from '../interactive-chat/Chat';
import { dpBgcolor, progressBarColor, progressBoxColor, progressTextColor } from '../utils/colors';
import eventEmitter from '../utils/event-emitter';
import store from '../../store';

class Intro extends Phaser.Scene {
    constructor() {
        super({ key: "intro" });

        this.gameWorld = {};
        this.gameWorld.previousCoordinates = {};
        this.gameWorld.avatar = null;
        this.gameWorld.doFade = false;
        this.gameWorld.onceFade = true;
        this.gameWorld.bgMusicPlaying = false;
    }

    preload() {
        this.preloadBackAndStaticObjs();
        this.preloadObjects();
        this.preloadEmptyObjects();
        this.preloadHints();
        this.preloadCorrectPositionedItems();
        this.preloadSounds();
        this.preloadInfoTerminals();
        this.preloadLoader();
        this.preloadHandAnim();
        this.preloadHitPapers();
        this.preloadNotLandScape();
        this.preloadImpressum();
    }

    create() {
        this.setBodyBackground();
        this.createNotLandscape();
        this.createBackCameraAvatar();
        this.playBgSound();
        this.endChat();
    }

    update() {
        this.checkAvatarCoordinates();
    }

    preloadHandAnim() {
        this.load.spritesheet("handAnim", "assets/handAnimation.png", { frameWidth: 640, frameHeight: 360 });
        this.load.spritesheet("dropHandAnim", "assets/dropHandAnimation.png", { frameWidth: 640, frameHeight: 360 });
    }

    preloadHitPapers() {
        this.load.image('animalHintPaper', `assets/detailviewsHints/hintsPapers/hintAnimalPaper.png`);
        this.load.image('appendixHintPaper', `assets/detailviewsHints/hintsPapers/hintAppendixPaper.png`);
        this.load.image('birdHintPaper', `assets/detailviewsHints/hintsPapers/hintBirdPaper.png`);
        this.load.image('gorillahandHintPaper', `assets/detailviewsHints/hintsPapers/hintGorillahandPaper.png`);
        this.load.image('revolutionaryHintPaper', `assets/detailviewsHints/hintsPapers/hintRevolutionaryPaper.png`);
        this.load.image('shirtHintPaper', `assets/detailviewsHints/hintsPapers/hintShirtPaper.png`);
        this.load.image('paintHintPaper', `assets/detailviewsHints/hintsPapers/hintPaintingPaper.png`);
    }

    preloadBackAndStaticObjs() {
        this.load.image('background', 'assets/BProom/background.png');
        this.load.image('column', 'assets/BProom/column.png');
        this.load.image('wall', 'assets/DProom/wall.png');
        this.load.image('dp3background', 'assets/DProom/background.png');
        this.load.spritesheet('avatarDp', 'assets/avatarDp.png', { frameWidth: 317, frameHeight: 452 });
        this.load.spritesheet('avatarOp', 'assets/avatarOp.png', { frameWidth: 97.5, frameHeight: 140 });
        this.load.image('dpArrowOut', 'assets/arrows/dpArrowOut.png');
        this.load.image('opArrowIn', 'assets/arrows/opArrowIn.png');
    }

    preloadObjects() {
        this.load.image('appendix', 'assets/BProom/objects/appendix.png');
        this.load.image('animal', 'assets/BProom/objects/animal.png');
        this.load.image('gorillahand', 'assets/BProom/objects/gorillahand.png');
        this.load.image('shirt', 'assets/BProom/objects/shirt.png');
        this.load.image('paint', 'assets/DProom/objects/paint.png');
        this.load.image('bird', 'assets/DProom/objects/bird.png');
        this.load.image('revolutionary', 'assets/DProom/objects/revolutionary.png');
    }

    preloadEmptyObjects() {
        this.load.image('emptyObject1', 'assets/BProom/empty-objects/emptyObject1.png');
        this.load.image('emptyObject1Shadow', 'assets/BProom/empty-objects/emptyObject1Shadow.png');

        this.load.image('emptyObject2', 'assets/BProom/empty-objects/emptyObject2.png');
        this.load.image('emptyObject2Shadow', 'assets/BProom/empty-objects/emptyObject2Shadow.png');

        this.load.image('emptyObject3', 'assets/BProom/empty-objects/emptyObject3.png');
        this.load.image('emptyObject3Shadow', 'assets/BProom/empty-objects/emptyObject3Shadow.png');
    }

    preloadHints() {
        this.load.image('hintPaint', 'assets/BProom/hints/hintPainting.png');

        this.load.image('hintGorillahand', 'assets/BProom/hints/hintGorillahand.png');
        this.load.image('hintGorillahandShadow', 'assets/BProom/hints/hintGorillahandShadow.png');

        this.load.image('hintAnimal', 'assets/BProom/hints/hintAnimal.png');
        this.load.image('hintAnimalShadow', 'assets/BProom/hints/hintAnimalShadow.png');

        this.load.image('hintRevolutionary', 'assets/BProom/hints/hintRevolutionary.png');
        this.load.image('hintRevolutionaryShadow', 'assets/BProom/hints/hintRevolutionaryShadow.png');

        this.load.image('hintShirt', 'assets/BProom/hints/hintShirt.png');
        this.load.image('hintShirtShadow', 'assets/BProom/hints/hintShirtShadow.png');

        this.load.image('hintAppendix', 'assets/BProom/hints/hintAppendix.png');
        this.load.image('hintAppendixShadow', 'assets/BProom/hints/hintAppendixShadow.png');

        this.load.image('hintBird', 'assets/BProom/hints/hintBird.png');
        this.load.image('hintBirdShadow', 'assets/BProom/hints/hintBirdShadow.png');
    }

    preloadCorrectPositionedItems() {
        this.load.image('gorillahandCorrect', 'assets/BProom/correctPositioned/gorillahand.png');
        this.load.image('animalCorrect', 'assets/BProom/correctPositioned/animal.png');
        this.load.image('appendixCorrect', 'assets/BProom/correctPositioned/appendix.png');
        this.load.image('shirtCorrect', 'assets/BProom/correctPositioned/shirt.png');
        this.load.image('revolutionaryCorrect', 'assets/BProom/correctPositioned/revolutionary.png');
        this.load.image('paintCorrect', 'assets/BProom/correctPositioned/painting.png');
        this.load.image('paintShadow', 'assets/BProom/correctPositioned/paintingShadow.png');
        this.load.image('birdCorrect', 'assets/BProom/correctPositioned/bird.png');
        this.load.image('birdShadow', 'assets/BProom/correctPositioned/birdShadow.png');
    }

    preloadSounds() {
        this.load.audio('bgSoundOP', 'assets/sounds/backgroundOPSound.mp3');
        this.load.audio('SoundDP', 'assets/sounds/backgroundDp.mp3');
        this.load.audio('successSound', 'assets/sounds/successSound.mp3');
        this.load.audio('failSound', 'assets/sounds/failSound.mp3');
        this.load.audio('walkSound', 'assets/sounds/walkSound.mp3');
        this.load.audio('btnClickedSound', 'assets/sounds/buttonClickedSound.mp3');
        this.load.audio('startChatSound', 'assets/sounds/startChatSound.mp3');
        this.load.audio('applauseSound', 'assets/sounds/applauseSound.mp3');

        this.load.audio('openHintsSound1', 'assets/sounds/openHintsSound1.mp3');
        this.load.audio('openHintsSound2', 'assets/sounds/openHintsSound2.mp3');
        this.load.audio('openHintsSound3', 'assets/sounds/openHintsSound3.mp3');
        this.load.audio('openHintsSound4', 'assets/sounds/openHintsSound4.mp3');
        this.load.audio('openHintsSound5', 'assets/sounds/openHintsSound5.mp3');
        this.load.audio('openHintsSound6', 'assets/sounds/openHintsSound6.mp3');
    }

    preloadInfoTerminals() {
        this.load.image('inactiveTerminal1', 'assets/BProom/infoTerminals/inactiveTerminal1.png');
        this.load.image('activeTerminal1', 'assets/BProom/infoTerminals/activeTerminal1.png');

        this.load.image('inactiveTerminal2', 'assets/BProom/infoTerminals/inactiveTerminal2.png');
        this.load.image('activeTerminal2', 'assets/BProom/infoTerminals/activeTerminal2.png');

        this.load.image('inactiveTerminal3', 'assets/BProom/infoTerminals/inactiveTerminal3.png');
        this.load.image('activeTerminal3', 'assets/BProom/infoTerminals/activeTerminal3.png');
    }

    preloadNotLandScape() {
        this.load.html('enNotLandscape', 'assets/NotLandscape/enLandscape.html');
        this.load.html('deNotLandscape', 'assets/NotLandscape/deLandscape.html');
    }

    preloadImpressum() {
        this.load.html('enImpressum', 'assets/EndOfGame/enImpressum.html');
        this.load.html('deImpressum', 'assets/EndOfGame/deImpressum.html');
    }

    preloadLoader() {
        const { width, height } = this.scale;
        this.cameras.main.setBackgroundColor(dpBgcolor);

        const progressBar = this.add.graphics();
        const progressBox = this.add.graphics({ x: width / 2, y: height / 2 });
        progressBox.clear()
        progressBox.fillStyle(progressBoxColor, 0.5);
        progressBox.fillRect(-620 / 2, -70 / 2, 620, 70);

        let textForLoading;
        const { language: { choosenLanguage } } = store.getState();
        if (choosenLanguage === 'en') {
            textForLoading = 'Loading...';
        }
        else if (choosenLanguage === 'de') {
            textForLoading = 'Wird geladen...';
        }

        const loadingText = this.add.text(width / 2, height / 2 - 80, textForLoading,
            { fontFamily: 'Arial', fontSize: 45, color: progressTextColor });
        loadingText.setOrigin(0.5, 0.5);

        const percentText = this.add.text(width / 2, height / 2, '0%',
            { fontFamily: 'Arial', fontSize: 35, color: progressTextColor });
        percentText.setOrigin(0.5, 0.5);

        this.load.on('progress', (value) => {
            progressBar.clear();
            progressBar.fillStyle(progressBarColor, 1);
            progressBar.fillRect((width - 600) / 2, (height - 50) / 2, 600 * value, 50);
            percentText.setText(`${parseInt(value * 100, 10)}%`);
        });

        this.load.on('complete', function () {
            progressBar.destroy();
            progressBox.destroy();
            loadingText.destroy();
            percentText.destroy();
        });
    }

    stopDpSoundBg() {
        this.gameWorld.bgMusic.pause();
        this.gameWorld.bgMusic.destroy();
        this.gameWorld.bgMusic = null;
    }

    playWalkSound() {
        if (!this.gameWorld.walkSound) {
            this.gameWorld.walkSound = this.sound.add('walkSound', { volume: 1, loop: true });
            this.gameWorld.walkSound.play();
        }
    }

    playBgSound() {
        if (this.gameWorld.bgMusicPlaying === false) {
            this.gameWorld.bgMusic = this.sound.add('SoundDP', { volume: 0.3, loop: true });
            this.gameWorld.bgMusic.play();
            this.gameWorld.bgMusicPlaying = true;
        }
    }

    playButtonPressedSound() {
        if (!this.gameWorld.buttonPressedSound &&
            !this.gameWorld.startChatSound) {
            this.gameWorld.buttonPressedSound = this.sound.add('btnClickedSound', { volume: 1 });
            this.gameWorld.startChatSound = this.sound.add('startChatSound', { volume: 1 });
            this.gameWorld.buttonPressedSound.play();
            this.gameWorld.startChatSound.play();
        }
        else {
            this.gameWorld.buttonPressedSound.play();
            this.gameWorld.startChatSound.play();
        }
    }

    stopWalkingSound() {
        if (this.gameWorld.walkSound) {
            this.gameWorld.walkSound.stop();
            this.gameWorld.walkSound = null;
        }
    }

    setBodyBackground() {
        document.body.style.background = '#000000';
    }

    createBackCameraAvatar() {
        const arrowOut = this.add.image(55, 1060, 'dpArrowOut').setOrigin(0, 0).setDepth(6);
        this.tweens.add({
            targets: arrowOut,
            alpha: { from: 0.3, to: 1 },
            ease: 'Cubic.easeOut',
            duration: 300,
            repeat: -1,
            yoyo: true
        });

        this.add.image(0, 0, 'wall').setOrigin(0);
        this.gameWorld.avatar = this.physics.add.image(944, 950, 'avatarDp').setOrigin(0.5, 0.75).setDepth(3);
        this.gameWorld.avatar.setTexture('avatarDp', 0);

        this.cameras.main.startFollow(this.gameWorld.avatar);
        this.cameras.main.setBounds(0, 0, 1920, 1200, true);
    }

    moveAvatarToDoor() {
        this.playWalkSound();
        const { avatar } = this.gameWorld;
        const moveTo = { x: 82, y: 1085 };

        const angle = Phaser.Math.Angle.BetweenPoints(avatar, moveTo) * 180 / Math.PI;

        if (angle < 115 && angle > 45) avatar.setTexture('avatarDp', 0);
        if (angle > 115 || angle < -115) avatar.setTexture('avatarDp', 1);
        if (angle > -45 && angle < 45) avatar.setTexture('avatarDp', 2);
        if (angle < -45 && angle > -115) avatar.setTexture('avatarDp', 3);
        this.physics.moveToObject(avatar, moveTo, 240);
    }

    startOnboarding() {
        this.scene.start('bigroomOnboarding');
    }

    checkAvatarCoordinates() {
        const { avatar, previousCoordinates } = this.gameWorld;
        const distance = Phaser.Math.Distance.Between(avatar.x, avatar.y, 82, 1085);
        previousCoordinates.x = avatar.x;
        previousCoordinates.y = avatar.y;

        if (avatar.body.speed > 0 && distance < 400) {
            this.gameWorld.doFade = true;
        }

        if (avatar.body.speed > 0 && distance < 100) {
            avatar.body.reset(previousCoordinates.x, previousCoordinates.y);
        }

        if (this.gameWorld.doFade && this.gameWorld.onceFade) {
            this.gameWorld.onceFade = false;
            this.cameras.main.fadeOut(1000, 201, 52, 126);
            this.cameras.main.once('camerafadeoutcomplete', () => {
                this.stopDpSoundBg();
                this.stopWalkingSound();
                this.startOnboarding();
            });
        }
    }

    showInteractiveChat(x, y) {
        if (!this.interactiveChatDOM) {
            this.interactiveChatDOM = document.getElementById("start-chat");
            this.interactiveChatDOM.addEventListener('click', this.onInteractiveChatClick.bind(this))
            this.gameWorld.interactiveChat = this.add.dom(x + 35, y - 450, this.interactiveChatDOM, {
                'pointer-events': 'auto'
            }).setOrigin(0, 0);
            this.animateAppear(this.gameWorld.interactiveChat);
        }
    }

    onInteractiveChatClick(event) {
        if (event.composedPath()[0].tagName === 'BUTTON') {
            this.playButtonPressedSound();
        }
    }

    closeInteractiveChat() {
        this.gameWorld.interactiveChat.destroy();
        this.gameWorld.interactiveChat = null;
    }

    startChat() {
        setTimeout(() => {
            this.showInteractiveChat(1010, 710);
        }, 1000)
    }

    endChat() {
        eventEmitter.on("CLOSE_START_CHAT", () => {
            this.interactiveChatDOM.removeEventListener('click', this.onInteractiveChatClick);
            this.interactiveChatDOM = null;
            new Promise(resolve => {
                setTimeout(() => {
                    const interactiveChat = document.getElementById("start-chat");
                    interactiveChat.style.display = 'none';
                    this.closeInteractiveChat();
                    resolve();
                }, 500);
            }).then(() => {
                return new Promise(resolve => {
                    setTimeout(() => {
                        this.moveAvatarToDoor();
                        resolve();
                    }, 1000);
                }).then(() => {
                    ReactDOM.render(<Chat startChat={false} endChat={false} />, document.getElementById('interactive-chat'));
                })
            })
        });
    }

    animateAppear(target) {
        this.tweens.add({
            targets: target,
            alpha: { from: 0, to: 1 },
            ease: 'Cubic.easeOut',
            duration: 1000,
            yoyo: false
        });
    }

    createNotLandscape() {
        const { language: { choosenLanguage } } = store.getState();
        if (choosenLanguage === 'en') {
            this.gameWorld.notLandscape = this.add.dom(0, 0).createFromCache('enNotLandscape').setOrigin(0, 0);
        }
        else if (choosenLanguage === 'de') {
            this.gameWorld.notLandscape = this.add.dom(0, 0).createFromCache('deNotLandscape').setOrigin(0, 0);
        }
        this.checkOrientation(this.scale.orientation);
        this.scale.on('orientationchange', this.checkOrientation, this);
    }

    checkOrientation(orientation) {
        if (orientation === Phaser.Scale.LANDSCAPE) {
            this.scale.off('orientationchange');
            this.gameWorld.notLandscape && this.gameWorld.notLandscape.destroy();
            this.gameWorld.notLandscape = null;
            this.startChat();
        }
    }
}

export default Intro;