import {
    BP_PICK_OBJ, CHANGE_CORE_GAMEPLAY_PHRASE, CHANGE_HINT_SOUND, CHANGE_LANGUAGE, CHANGE_PHRASE,
    DISABLE_CHARACTER_MOVING, DP1_PICK_OBJ, DP2_PICK_OBJ, DP3_PICK_OBJ, INVENTORY_ADD_COPY_OF_OBJECT, INVENTORY_PICK_OBJ,
    INVENTORY_SET_CORRECT_POSITION, INVENTORY_SET_IN_INVENTORY_PROP, INVENTORY_SET_LOCATION_PROP, SET_AVATAR_XY, SET_CORRECT_ALL,
    SET_RUN_END_OF_GAME, WATCHED_TERMINAL_GUIDES, SET_VIDEO_PLAYING
} from "./types"

export const setAvatarXY = ({ x, y }) => {
    return {
        type: SET_AVATAR_XY,
        payload: { x, y }
    };
}

export const bpPickObj = (objectName, taken) => {
    return {
        type: BP_PICK_OBJ,
        payload: { name: objectName, taken }
    };
}

export const dp1PickObj = (objectName, taken) => {
    return {
        type: DP1_PICK_OBJ,
        payload: { name: objectName, taken }
    };
}

export const dp2PickObj = (objectName, taken) => {
    return {
        type: DP2_PICK_OBJ,
        payload: { name: objectName, taken }
    };
}

export const dp3PickObj = (objectName, taken) => {
    return {
        type: DP3_PICK_OBJ,
        payload: { name: objectName, taken }
    };
}

export const inventoryPickObj = (objectCopyName, objectToDrag) => {
    return {
        type: INVENTORY_PICK_OBJ,
        payload: { name: objectCopyName, objectToDrag }
    };
}

export const inventroySetCorrectPosition = (itemName, correctPosition) => {
    return {
        type: INVENTORY_SET_CORRECT_POSITION,
        payload: { name: itemName, correctPosition }
    };
}

export const inventoryAddCopyOfObject = (objectCopyName, objectToDrag) => {
    return {
        type: INVENTORY_ADD_COPY_OF_OBJECT,
        payload: { name: objectCopyName, objectToDrag }
    };
}

export const inventorySetInProp = (name, inInventory) => {
    return {
        type: INVENTORY_SET_IN_INVENTORY_PROP,
        payload: { name, inInventory }
    };
}

export const inventorySetLocationProp = (action) => {
    return {
        type: INVENTORY_SET_LOCATION_PROP,
        payload: action
    };
}

export const changeObjectPhrase = (phraseNum) => {
    return {
        type: CHANGE_CORE_GAMEPLAY_PHRASE,
        payload: phraseNum
    };
}

export const changePhrase = (number) => {
    return {
        type: CHANGE_PHRASE,
        payload: number
    }
}

export const disableCharacterMoving = (disable) => {
    return {
        type: DISABLE_CHARACTER_MOVING,
        payload: disable
    }
}


export const changeLanguage = (language) => {
    return {
        type: CHANGE_LANGUAGE,
        payload: language
    }
}

export const changeHintSound = () => {
    return {
        type: CHANGE_HINT_SOUND
    }
}

export const setCorrectAll = () => {
    return {
        type: SET_CORRECT_ALL
    }
}

export const setWatchedGuides = () => {
    return {
        type: WATCHED_TERMINAL_GUIDES
    }
}

export const setRunEndOfGame = (scene) => {
    return {
        type: SET_RUN_END_OF_GAME,
        payload: scene
    }
}

export const setVideoPlaying = (videoPlaying) => {
    return {
        type: SET_VIDEO_PLAYING,
        payload: videoPlaying
    }
}