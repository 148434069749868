import {
    speechBubbleColor, speechBubbleLineColor, speechBubbleTextBoxColor,
    speechBubbleTextBoxLineColor, speechBubbleTextColor
} from './colors';

export default class SpeechBubble {
    constructor(context) {
        this.bubble = null;
        this.text = null;
        this.textBox = null;
        this.context = context;
    }

    create(x, y, width, height, quote) {
        const bubbleWidth = width;
        const bubbleHeight = height;
        const textPadding = 16;
        const textBoxPadding = 21;
        const arrowWidth = bubbleWidth / 7.15;

        this.bubble = this.context.add.graphics({ x, y }).setDepth(5);
        this.bubble.fillStyle(speechBubbleColor, 1);
        this.bubble.lineStyle(4, speechBubbleLineColor, 1);
        this.bubble.strokeRect(0, 0, bubbleWidth, bubbleHeight);
        this.bubble.fillRect(0, 0, bubbleWidth, bubbleHeight);

        const point1X = 0;
        const point1Y = Math.floor(bubbleHeight / 1.5);
        const point2X = 0;
        const point2Y = Math.floor(bubbleHeight / 1.15);
        const point3X = 0 - Math.floor(arrowWidth);
        const point3Y = bubbleHeight;

        this.bubble.fillTriangle(point1X, point1Y, point2X, point2Y, point3X, point3Y);
        this.bubble.lineStyle(2, speechBubbleLineColor, 1);
        this.bubble.lineBetween(point2X, point2Y, point3X, point3Y);
        this.bubble.lineBetween(point1X, point1Y, point3X, point3Y);

        const textBoxWidth = bubbleWidth - (textBoxPadding * 2);
        const textBoxHeight = bubbleHeight - (textBoxPadding * 2);
        this.textBox = this.context.add.graphics({ x, y }).setDepth(5);
        this.textBox.fillStyle(speechBubbleTextBoxColor, 1);
        this.textBox.lineStyle(4, speechBubbleTextBoxLineColor, 1);
        this.textBox.strokeRect(textBoxPadding, textBoxPadding, textBoxWidth, textBoxHeight);
        this.textBox.fillRect(textBoxPadding, textBoxPadding, textBoxWidth, textBoxHeight);

        this.text = this.context.add.text(0, 0, quote,
            {
                fontFamily: 'Arial', fontSize: 34, color: speechBubbleTextColor,
                align: 'center', wordWrap: { width: textBoxWidth - (textPadding * 2) }
            })
            .setDepth(5);

        const b = this.text.getBounds();
        this.text.setPosition(this.textBox.x + ((textBoxWidth + textPadding * 2) / 2) - (b.width / 2),
            this.textBox.y + ((textBoxHeight + textBoxPadding * 2) / 2) - (b.height / 2));

        this.context.tweens.add({
            targets: [this.bubble, this.textBox, this.text],
            alpha: { from: 0, to: 1 },
            ease: 'Cubic.easeOut',
            duration: 1000,
            yoyo: false
        })
    }

    destroy() {
        this.text && (this.text.destroy());
        this.bubble && (this.bubble.destroy());
        this.textBox && (this.textBox.destroy());
    }
}
