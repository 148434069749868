import React from 'react';
import Phaser from 'phaser';

import BPRoom from './rooms/BProom';
import InventoryUI from './inventory/InventoryUI';
import BProomOnboarding from './rooms/BProomOnboarding'
import hintScene from './hints/hintScene';
import DPRoom from './rooms/DPRoom';
import Intro from './rooms/Intro';
import EndGame from './rooms/EndOfGame';

class Game extends React.Component {
  componentDidMount() {
    const config = {
      transparent: true,
      type: Phaser.AUTO,
      scale: {
        parent: "game",
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        width: 1920,
        height: 1200,
      },
      dom: {
        createContainer: true
      },
      physics: {
        default: 'arcade'
      },
      scene: [Intro, BProomOnboarding, BPRoom, DPRoom, hintScene, InventoryUI, EndGame]
    };
    new Phaser.Game(config);
  }

  render() {
    return <div id="game" />
  }
}

export default Game;
