import {
    INVENTORY_PICK_OBJ, INVENTORY_SET_IN_INVENTORY_PROP, INVENTORY_SET_LOCATION_PROP,
    INVENTORY_SET_CORRECT_POSITION, INVENTORY_ADD_COPY_OF_OBJECT, SET_CORRECT_ALL, WATCHED_TERMINAL_GUIDES,
    SET_VIDEO_PLAYING,
} from "../types";

const initState = {
    objects: [],
    correctAll: false,
    watchedTerminalGuides: 0,
    videoPlaying: false,
};

export default (
    state = initState,
    action
) => {
    switch (action.type) {
        case INVENTORY_PICK_OBJ:
            {
                const objToAdd = { ...action.payload, position: state.objects.length }
                return { ...state, objects: [...state.objects, objToAdd] }
            }
        case INVENTORY_SET_IN_INVENTORY_PROP:
            return {
                ...state, objects: state.objects.map(elem => {
                    if (elem.name === action.payload.name) {
                        elem.objectToDrag.inInventory = action.payload.inInventory
                    }
                    return elem
                })
            }
        case INVENTORY_SET_LOCATION_PROP:
            return {
                ...state, objects: state.objects.map(elem => {
                    if (elem.name === action.payload.name) {
                        elem.objectToDrag.x = action.payload.x;
                        elem.objectToDrag.y = action.payload.y;
                        elem.objectToDrag.located = action.payload.located;
                    }
                    return elem;
                })
            }
        case INVENTORY_SET_CORRECT_POSITION:
            return {
                ...state, objects: state.objects.map(elem => {
                    if (elem.name === action.payload.name) {
                        elem.correctPosition = action.payload.correctPosition;
                    }
                    return elem;
                })
            }
        case INVENTORY_ADD_COPY_OF_OBJECT:
            return {
                ...state, objects: state.objects.map(elem => {
                    if (elem.name === action.payload.name) {
                        elem.objectToDrag = action.payload.objectToDrag;
                    }
                    return elem;
                })
            }
        case SET_CORRECT_ALL: {
            let correctAll = false;
            if (state.objects.length === 7
                && state.objects.filter(item => item.correctPosition).length === 7) {
                correctAll = true;
            }
            return {
                ...state, correctAll
            }
        }
        case WATCHED_TERMINAL_GUIDES:
            return {
                ...state, watchedTerminalGuides: state.watchedTerminalGuides + 1
            }
        case SET_VIDEO_PLAYING:
            return {
                ...state, videoPlaying: action.payload
            }
        default:
            return state;
    }
};