import { CHANGE_LANGUAGE } from "../types";

const initState = {
    choosenLanguage: 'en',
};

export default (
    state = initState,
    action
) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return { ...state, choosenLanguage: action.payload };
        default:
            return state;
    }
};